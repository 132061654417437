import Backbone from 'backbone';
import Handlebars from 'handlebars';
import Settings from '~/settings.js';

import EventHub from '~/eventHub';
import super_category_template from '~/templates/report/selectSuperCategory.handlebars';
import category_template from '~/templates/report/selectCategory.handlebars';
import getReportCategoryService from '~/services/report/reportCategoryService';
import getReportSubCategoryService from '~/services/report/reportSubCategoryService';
import getReportSuperCategoryService from '~/services/report/reportSuperCategoryService';
import getUtilsService from '~/services/common/utilsService';


var SelectCategoryView = Backbone.View.extend({
    tagName: "div",
    className: 'select-category',
    userId: '',
    user: null,
    superCategories: null,
    selectedSuperCategory: '',
    categories: null,
    datax: null,
    supercategoriid: null,
    fromsearch: '',
    customformid : '',
    initialize: function() {
      $('.region-content-row').css('background','url("images/new/fondo.jpg") no-repeat center');
      $('.region-content-row').css('background-size','cover');
      $.get("images/new/fondo-categorias.jpg").done(function () {
        $('.region-content-row').css('background','url("images/new/fondo-categorias.jpg") no-repeat center');
        $('.region-content-row').css('background-size','cover');
      });
    },
    events: {
      'click .category': 'renderCategories',
      'click .back': 'back',
      'click .normal-category': 'goToDetails',
      'click .normal-subcategory': 'goToDetailsWithSubcategory',
      'click #closegoback':'cancel',
    },

    render: function () {
      this.attachEvents();
      this.translate();
      return this;
    },

    attachEvents: function() {
        this.listenTo(EventHub, 'sendsearchcode', data => {
            this.fromsearch = data.searcharr.fromsearch;
            this.supercategoriid = data.searcharr.id;
            this.renderCategories();
        });
        this.listenTo(EventHub, 'report:details2', dataz => {
            this.datax = dataz;
            this.supercategoriid =this.datax.supercategory;
            this.renderSupercategories();
        });
    },

    translate: function(e){
      var that = this;
      var callback = data => {
        if(arrlang[lang] === undefined){ return }

        that.$el.find('.item').each(function(index, element){
          $(this).html(arrlang[lang][$(this).attr("key")]);
        });

        that.$el.find('.normal-subcategory').each(function(index, element){
          if($(this).text().length > 0){
            var entry = arrlang[lang]["Supercategories"][$(this).attr("superCat")];
            if(entry === undefined || entry[$(this).attr("id")] === undefined){ return }
            $(this).text(entry[$(this).attr("id")]);
          }
        });

        that.$el.find('.category-container .category').each(function(index, element){
          var entry = arrlang[lang]["Supercategories"][$(this).attr("id")];
          if(entry === undefined){ return }
          $(this).find('.Crear-reporte').text(entry["Name"]);
        });

        that.$el.find('.category-report .cat-description').each(function(index, element){
          var entry = arrlang[lang]["Supercategories"][$(this).attr("superCat")];
          if(entry === undefined){ return }
          $(this).text(entry["Description"]);
        });

        that.$el.find('.subcategoryText').each(function(index, element){
          var entry = arrlang[lang]["Supercategories"][$(this).attr("superCat")];
          if(entry === undefined){ return }
          $(this).text(entry["Name"]);
        });
      }

      if(Object.keys(window.arrlang).length !== 0){
        callback();
      }
      this.listenTo(EventHub, 'translation', callback);
    },

    renderSupercategories: function() {
      if (this.supercategoriid) {
        var that = this;
        setTimeout(function(){ that.renderCategories(); }, 10);
      }
        var superCategoryPromise = getReportSuperCategoryService().fetch({
            all: true,
            cache: true
        }).then(superCategories => {
            var handlebarsTemplate = Handlebars.template(super_category_template);
            this.superCategories = superCategories.models;
            for(var i=0; i< superCategories.models.length; ++i){

              if(superCategories.models[i].attributes.active){
                if(superCategories.models[i].attributes.iconFile == undefined)
                {
                  superCategories.models[i].iconLocalURL = "images/new/icons/7sO96C21Pf.svg";
                }else {
                  superCategories.models[i].iconLocalURL = superCategories.models[i].attributes.iconFile._url;
                }
              }
            }
            var html = handlebarsTemplate({
                models: superCategories.models,
            });
            if(this.fromsearch != true){
              window.spinnerDialog.show();
              var platform = getUtilsService.getPlatform();
              this.$el.html(html);
              if (Settings.shogunmonitorapp && platform.browser) {
                EventHub.trigger('navigate', 'search', { trigger: true });
                window.spinnerDialog.hide();
              }
            }
            this.translate();
            var icons = this.$el.find('.icon');
            for (var i = 0; i < icons.length; ++i) {
                $(icons[i]).append(superCategories.models[i].attributes.icon);
            }
            if(arrlang[lang]["OpeningHours1"] == undefined && arrlang[lang]["OpeningHours2"] == undefined){
              $(".openingHourscontainer").remove();
            }
            window.spinnerDialog.hide();
        });
    },
    
    renderCategories: function(e){
      console.log('subcategorias');
      window.spinnerDialog.show();
      this.customformid = '';
      if (this.supercategoriid) {
        this.selectedSuperCategory = this.supercategoriid;
      }else{
        this.selectedSuperCategory = jQuery(e.currentTarget).attr('id');
      }
      var that = this;
      var categoryPromise = getReportSuperCategoryService().fetch({
        all: true,
        cache: true,
        rules: {equals: [{key: 'objectId',val:  this.selectedSuperCategory}]}
      });
        var subCategoryPromise = getReportSubCategoryService().fetch({
          all: true,
          cache: true,
          rules : {equals :[{key: 'superCat', val: {__type: "Pointer", className: "superCategory", objectId: this.selectedSuperCategory}},{key: 'active',val: true}]}
        });
      Promise.all([categoryPromise,subCategoryPromise]).then(([categories,subCategories]) =>{
        that.categories = categories.models;
        var handlebarsTemplate = Handlebars.template(category_template);
        var name = '';
        var supercatdescription = '';
        var icon;
        var icon2 = '';
        var applinks = that.categories[0].attributes.appslinks;
        if (that.categories[0].attributes.customform != undefined) {
          that.customformid = that.categories[0].attributes.customform.id;
        }
        if( that.categories[0].attributes.iconFile != undefined){
          icon2 = that.categories[0].attributes.iconFile._url;
        } else{
          icon2 = "images/new/icons/7sO96C21Pf.svg";
        }
        name = that.categories[0].attributes.name;
        supercatdescription = that.categories[0].attributes.description;
        this.$el.html(handlebarsTemplate({
          platform: getUtilsService.getPlatform(), subCategories: subCategories.models,
          supercategory: name, supercategorydes: supercatdescription, applinks: applinks, superCatId: this.selectedSuperCategory
        }));
        $('#topimg').attr("src", icon2);
        window.spinnerDialog.hide();
        $('.category-report').css('background','url("images/new/fondo.jpg") no-repeat center');
        $('.category-report').css('background-size','cover');
        $.get("images/new/fondo-subcategorias.jpg").done(function () {
          $('.category-report').css('background','url("images/new/fondo-subcategorias.jpg") no-repeat center');
          $('.category-report').css('background-size','cover');
        });
        this.translate();
      });
      this.supercategoriid = null;
    },

    cancel: function() {
        EventHub.trigger('navigate', 'login', {
            trigger: true
        });
    },
    back: function() {
      if (localStorage.searchx == 'true') {
        EventHub.trigger('navigate', 'search', { trigger: true });
      }else{
        EventHub.trigger('navigate', 'home', {
            trigger: true
        });
      }
    },
    goToDetails: function(e){
      EventHub.trigger('navigate', 'insertdetailsreport?&category='+jQuery(e.currentTarget).attr('id')+'&supercategory='+jQuery(e.currentTarget).attr('superCat'), { trigger: true });
    },
    goToDetailsWithSubcategory: function(e){
      var subcategorycustomform = jQuery(e.currentTarget).attr('customform');
        if(subcategorycustomform == ''){
          EventHub.trigger('navigate', 'insertdetailscustomreport?supercateg='+this.selectedSuperCategory+'&subcateg='+jQuery(e.currentTarget).attr('id')+'&form='+this.customformid, { trigger: true });
          this.customformid = '';
        }else{
          EventHub.trigger('navigate', 'insertdetailscustomreport?supercateg='+this.selectedSuperCategory+'&subcateg='+jQuery(e.currentTarget).attr('id')+'&form='+subcategorycustomform, { trigger: true });
        }
    }
});
export default SelectCategoryView;
