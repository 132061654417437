import Backbone from 'backbone';

import ParseService from '~/services/common/parseService';
import Model from '~/models/parseModel';
import Collection from '~/collections/parseCollection';
import getUtilsService from '~/services/common/utilsService';

var fileService;
export default function () {

    var FileModel = Model.extend({
        className: "Files",
        defaults: {
            'manualTitle': false,
        },
    });

    var FileCollection = Collection.extend({
        model: FileModel,
    });
    var FileService = ParseService.extend({
        sortBy: 'createdAt',
        itemsOnPage: 100, // load by N items, this overrides the 1k limit 'parseService.js'
        model: FileModel,
        collection: FileCollection,
        createSimpleFile: function(picture, reportID, commentid, success, error){
          var that = this;
          getUtilsService.setUpFileLoader();
          picture.save({
            progress: (progressValue, loaded, total, { type }) => {
              if (type === "upload" && progressValue !== null) {
                getUtilsService.fileProgress(progressValue);
              }
            }
          }).then(function() {
            getUtilsService.resetFileLoader();
            var model = new that.model("Files");
            model.set('report',{__type: "Pointer", className: "Report", objectId: reportID});
            model.set('file', picture);
            model.set('comment',{__type: "Pointer", className: "ReportComments", objectId: commentid});
            model.save().then((succ)=> {
              success(success);
            },(err)=>{
              error(err);
            });
          }, function(error) {
            getUtilsService.resetFileLoader();
            error(error);
          });
        },
        createSimplecustomFile: function(picture, customformfieldname, success, error){
          var that = this;
          picture.save({
          }).then(function() {
            var model = new that.model("Files");
            model.set('file', picture);
            model.set('customformfield', customformfieldname);
            model.save().then((succ)=> {
              success(succ);
            },(err)=>{
              error(err);
            });
          }, function(error) {
            getUtilsService.resetFileLoader();
            error(error);
          });
        },
    });

    return fileService || (fileService = new FileService());
};
// only created once for application
