import Backbone from 'backbone';

import ParseService from '~/services/common/parseService';
import Model from '~/models/parseModel';
import Collection from '~/collections/parseCollection';

var newsService;
export default function() { // lazy initialization. we export function which returns servies. this way service will be created only when it will be really necessary

    // model
    var NewsModel = Model.extend({
        className: "News",
        defaults: {
            'manualTitle': false,
        }
    });

    // collection
    var NewsCollection = Collection.extend({
        model: NewsModel
    });

    // service (extends ParseService)
    var NewsService = ParseService.extend({
        sortBy: '-createdAt',
        model: NewsModel,
        collection: NewsCollection,
        // you can also extend any method from ParseService here
    });

    return newsService || (newsService = new NewsService());
};
// only created once for application
