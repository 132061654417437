export default {"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.active : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":13},"end":{"line":31,"column":21}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.propertyIsEnumerable, alias2=container.lambda, alias3=container.escapeExpression;

  return "              <div class=\"category\" id=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.id : stack1), depth0))
    + "\">\r\n                <div class=\"mainIcon\">\r\n                  <img src=\""
    + alias3(((helper = (helper = helpers.iconLocalURL || (depth0 != null ? depth0.iconLocalURL : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"iconLocalURL","hash":{},"data":data,"loc":{"start":{"line":25,"column":28},"end":{"line":25,"column":44}}}) : helper)))
    + "\" class=\"icon\">\r\n                </div>\r\n                <div class=\"Crear-reporte\">\r\n                  "
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.name : stack1), depth0))
    + "\r\n                </div>\r\n              </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable;

  return "<div class=\"superCat col-xs-12 col-sm-6 col-sm-offset-3\">\r\n  <div class=\"text-center\">\r\n    <div class=\"text-center\">\r\n      <img src=\"images/new/shogun_SG.png\"  class=\"header-logo\">\r\n      <div class=\"category-title\">\r\n        <!--removeIf(capacitaOnline)-->\r\n        <!--removeIf(sagradafamiliaOnline)-->\r\n        <div class=\"cat-desc item\" key='SelectCategory'> Seleccione una categoría </div>\r\n        <!--endRemoveIf(sagradafamiliaOnline)-->\r\n        <div class=\"cat-title item\" key='ReportActivity'> Reporta una actividad </div>\r\n        <!--endRemoveIf(capacitaOnline)-->\r\n        \r\n    </div>\r\n  </div>\r\n\r\n    <div class=\"row\">\r\n      <div class=\"col-sm-12\">\r\n        <div class=\"container\">\r\n          \r\n          <div class=\"category-container\">\r\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.models : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":32,"column":23}}})) != null ? stack1 : "")
    + "          </div>\r\n      </div>\r\n      </div>\r\n\r\n    </div><div class = \"row\">\r\n    <div class=\"col-md-12 row-2\">\r\n        <div class=\"cancel-button standar-button\" id=\"closegoback\"><div class=\"item\" key=\"BackBtn\">REGRESAR</div></div>\r\n        <div class=\"openingHourscontainer\">\r\n          <p class=\"openingHourstext\"><span class=\"item\" key=\"OpeningHours1\"></span> <br> <span class=\"item\" key=\"OpeningHours2\"></span></p>\r\n        </div> \r\n        <img class=\"footer-logo\" src=\"images/new/shogun_logo_blanco.png\">\r\n    </div>\r\n    </div>\r\n  </div>\r\n</div>\r\n";
},"useData":true};