import Backbone from 'backbone';

import ParseService from '~/services/common/parseService';
import Model from '~/models/parseModel';
import Collection from '~/collections/parseCollection';

var alertsService;
export default function() { // lazy initialization. we export function which returns servies. this way service will be created only when it will be really necessary

    // model
    var AlertsModel = Model.extend({
        className: "Alerts",
        defaults: {
            'manualTitle': false,
        },

    });

    // collection
    var AlertsCollection = Collection.extend({
        model: AlertsModel
    });

    // service (extends ParseService)
    var ReportCategoryService = ParseService.extend({
        sortBy: 'createdAt',
        model: AlertsModel,
        collection: AlertsCollection,
        // you can also extend any method from ParseService here
    });

    return alertsService || (alertsService = new ReportCategoryService());
};
// only created once for application
