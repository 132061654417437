import Backbone from 'backbone';

import ParseService from '~/services/common/parseService';
import Model from '~/models/parseModel';
import Collection from '~/collections/parseCollection';

var reportCategoryService;
export default function() { // lazy initialization. we export function which returns servies. this way service will be created only when it will be really necessary

    // model
    var ReportCategoryModel = Model.extend({
        className: "ReportCategory",
        defaults: {
            'manualTitle': false,
        },
        
        checkIconType: function() { // this funciton is also on the report model... bad practice to have 2 times the same fucntion. should be actually only ghere.
            // this function returns the icon type.
            //return: 'SVG' or 'ion'
            var thisReportType = '';
            var reportIconRaw = this.attributes.icon;
            if (reportIconRaw.substring(2, 5) == 'xml') {
                // SVG icon
                thisReportType = 'SVG';
            } else if (reportIconRaw.substring(0, 3) == 'ion') {
                // ion icon.
                thisReportType = 'ion';
            }

            this._type = thisReportType;
            return thisReportType;
        } // end checkIconType
    });

    // collection
    var ReportCategoryCollection = Collection.extend({
        model: ReportCategoryModel
    });

    // service (extends ParseService)
    var ReportCategoryService = ParseService.extend({
        sortBy: 'catId',
        model: ReportCategoryModel,
        collection: ReportCategoryCollection,
        // you can also extend any method from ParseService here
    });

    return reportCategoryService || (reportCategoryService = new ReportCategoryService());
};
// only created once for application
