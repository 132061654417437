export default {"1":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"title subcategory\"></div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                   <div class=\"report-title item\" key=\"ReportDetailTittle\">Estatus del reporte</div>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                  <div class=\"statusMessage item\" key=\"ReportStatus2\">Cerrado</div>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.discard : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":29,"column":20},"end":{"line":33,"column":27}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"statusMessage item\" key=\"ReportStatus3\">Descartado</div>\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"statusMessage item\" key=\"ReportStatus1\">En proceso...</div>\r\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable;

  return "                  <h5 class=\"reportfilestittle item\" key=\"Attachfilesreport\">Archivos adjuntos:</h5>\r\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.repfiles : depth0)) != null ? stack1.models : stack1),{"name":"each","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":20},"end":{"line":47,"column":29}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable, alias2=container.lambda, alias3=container.escapeExpression;

  return "                      <div class='file'>\r\n                         <a onclick=\"cordova.InAppBrowser.open('"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.file : stack1)) != null ? stack1._url : stack1), depth0))
    + "', '_system', 'location=yes');\" href=\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.file : stack1)) != null ? stack1._url : stack1), depth0))
    + "\"target=\"_blank\">"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.file : stack1)) != null ? stack1._name : stack1), depth0))
    + "</a>\r\n                      </div>\r\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable;

  return "                   <h5 class=\"reportfilestittle item\">Conversaci&oacute;n con <b>denunciante</b></h5>\r\n                   <div class=\"containerreporthistorycomemntstittle\">\r\n                     <h5 class=\"reporthistorycomemntstittle item\">Historial</h5>\r\n                     <div class=\"separator3\"></div>\r\n                   </div>\r\n                 <div class=\"div-coment\" id=\"togglecoments\">\r\n                    <ul class=\"coments\">\r\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.comments : depth0)) != null ? stack1.models : stack1),{"name":"each","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":23},"end":{"line":91,"column":32}}})) != null ? stack1 : "")
    + "                    </ul>\r\n                 </div>\r\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable;

  return "                       <li class=\"commentcontainer\">\r\n                          <div class=\"row interno\">\r\n                              <div class=\"col-sm-6 colmn\">\r\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.user : stack1)) != null ? stack1.attributes : stack1)) != null ? stack1.firstName : stack1),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":63,"column":32},"end":{"line":87,"column":33}}})) != null ? stack1 : "")
    + "                          <div class=\"commentfilescontainer\" id=\"comment-"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.commentid : stack1), depth0))
    + "\"></div>\r\n                          <div class=\"separator2\"></div>\r\n                       </li>\r\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable, alias2=container.lambda, alias3=container.escapeExpression;

  return "                                  <div class=\"comemntuser pull-left\">"
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.user : stack1)) != null ? stack1.attributes : stack1)) != null ? stack1.firstName : stack1), depth0))
    + " "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.user : stack1)) != null ? stack1.attributes : stack1)) != null ? stack1.lastName : stack1), depth0))
    + ":<br><span class=\"commentdate pull-left\">"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.createdAt : stack1), depth0))
    + "</span></div>\r\n                                  <div class=\"coment responsiv pull-left\">\r\n                                       <span class=\"linkify_comment\">"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.comment : stack1), depth0))
    + "</span>\r\n                                 </div>\r\n                               </div>\r\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable;

  return "                          <div class=\"comemntuser\">\r\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.auditor : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":71,"column":28},"end":{"line":79,"column":22}}})) != null ? stack1 : "")
    + "                          </div>\r\n                            <div class=\"col-sm-6 colmn\">\r\n                              <div class=\"coment responsiv pull-right\">\r\n                                <span class=\"linkify_comment\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.comment : stack1), depth0))
    + "</span>\r\n                              </div>\r\n                            </div>\r\n                          </div>\r\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable;

  return "                              <span class=\"item\" key=\"ReportCommentAdmin\">Administrador:</span><span class=\"commentdate pull-right\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.createdAt : stack1), depth0))
    + "</span></div>\r\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.userName : stack1),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":74,"column":33},"end":{"line":78,"column":40}}})) != null ? stack1 : "");
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable;

  return "                                      <span class=\"item\" >"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.userName : stack1), depth0))
    + "</span>\r\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable;

  return "                                      <span class=\"item\" key=\"ReportCommentAnonymous\">Usuario Anónimo:</span><span class=\"commentdate pull-right\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.createdAt : stack1), depth0))
    + "</span></div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.propertyIsEnumerable, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "<section class=\"display_report\">\r\n   <div class=\"container principal\">\r\n     <div class=\"row\">\r\n      <div class=\"col-xs-12 col-sm-6  col-sm-offset-3 \">\r\n         <div class=\"panel-body\">\r\n          <div class=\"commentscont\" >\r\n           <center>\r\n           \r\n          <!--removeIf(showtopimgethicline)-->\r\n            <img src=\"images/new/shogun_SG.png\" class=\"header-logo\">\r\n          <!--endRemoveIf(showtopimgethicline)-->\r\n           </center>\r\n                 <center>\r\n                    <div class=\"title\">"
    + alias2(container.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.category : stack1)) != null ? stack1.attributes : stack1)) != null ? stack1.name : stack1), depth0))
    + "</div>\r\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.subCategory : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":20},"end":{"line":17,"column":27}}})) != null ? stack1 : "")
    + "                 </center>\r\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.description : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":17},"end":{"line":21,"column":24}}})) != null ? stack1 : "")
    + "\r\n              </br>\r\n              <div class=\"Mask\">\r\n                  <h1 class=\"masktitle\">Estado de la denuncia</h1>\r\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.close : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":26,"column":18},"end":{"line":34,"column":25}}})) != null ? stack1 : "")
    + "                  <hr class=\"maskseparator\">\r\n                  <button class=\"btn btnmodalcustomformdetails item\"  key=\"btnshowcustomformdetailsmodal\">Ver detalles de la denuncia</button>\r\n                  <br>\r\n                  <button class=\"btn btncomment item\" id=\"comment-img\" key=\"ReportNewComment\">Crear comentario</button>\r\n              </div>\r\n              <div class=\"files\">\r\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.repfiles : depth0)) != null ? stack1.models : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":16},"end":{"line":48,"column":23}}})) != null ? stack1 : "")
    + "              </div>\r\n              <div class=\"commentaries\">\r\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.comments : depth0)) != null ? stack1.models : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":17},"end":{"line":94,"column":24}}})) != null ? stack1 : "")
    + "              </div> <!-- fin del body-->\r\n              <br>\r\n   <div class=\"cancel-button standar-button\"><div class=\"item\" key=\"BackBtn\">REGRESAR</div>\r\n </div>\r\n   <center>\r\n   <img class=\"footer-logo\" src=\"images/new/shogun_logo_blanco.png\">\r\n   <br><br>\r\n   </center>\r\n   </div>\r\n    <div class=\"addComentaryBox\" id=\"addComentaryBox\"></div>\r\n    <div class=\"addComentaryThks\" id=\"addComentaryThks\"></div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div style=\"display:none;\" id=\"customformdetailsmodal\"  class=\"modal\" role=\"dialog\" >\r\n  	    <div class=\"modal-dialog\">\r\n  	      <div class=\"modal-content\">\r\n            <div class=\"report-subtitle item\" key=\"ReportDetailSubTittle\">Descripci&oacute;n del reporte o denuncia:</div>\r\n            <div class=\"reportdetailclosemodalicon\">\r\n              <i class=\"fa fa-times-circle\" aria-hidden=\"true\"></i>\r\n            </div>\r\n            <p class=\"report-description-field2\" id=\"report-description-field\"><span class=\"reportcreationdatetext item\" key=\"\">Fecha de creaci&oacute;n: </span><span class=\"reportcreationdate item\">"
    + alias2(((helper = (helper = helpers.reportcreatedat || (depth0 != null ? depth0.reportcreatedat : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias3,{"name":"reportcreatedat","hash":{},"data":data,"loc":{"start":{"line":116,"column":199},"end":{"line":116,"column":218}}}) : helper)))
    + "</span></p>\r\n            <div id=\"contentcustomreportdetails\"></div>\r\n            <div class=\"returnbtncontainer\">\r\n  					  <div class=\"standar-button cancel-modal cancel-modal \" id=\"modalcustomform-cancel\"><div class=\"item\" key=\"BackBtn\">Regresar</div></div>\r\n            </div>\r\n           </div>\r\n  	      </div>\r\n  	    </div>\r\n  </div>\r\n</section>\r\n";
},"useData":true};