export default {"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.propertyIsEnumerable;

  return "        <span>"
    + container.escapeExpression(((helper = (helper = helpers.val || (depth0 != null ? depth0.val : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"val","hash":{},"data":data,"loc":{"start":{"line":4,"column":14},"end":{"line":4,"column":21}}}) : helper)))
    + "</span>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <span>...</span>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div id=\"containercustomreportdetails\">\r\n    <span><i class=\"fa fa-circle\" aria-hidden=\"true\"></i> <b>"
    + container.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias2,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":2,"column":61},"end":{"line":2,"column":69}}}) : helper)))
    + "</b></span>\r\n"
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.val : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":7,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"customseparator\"></div>\r\n</div>\r\n";
},"useData":true};