import Backbone from 'backbone';
import Handlebars from 'handlebars';

import EventHub from '~/eventHub';
import confirmreporttemplate from '~/templates/report/confirmreport.handlebars';

/**
 * Category View (show category title and a report ranking of a report)
 * @constructor
 */


var confirmreportView = Backbone.View.extend({
    tagName: "div",
    className: 'confirm-report',
    userId: '',
    events: {},
    user: null,

    initialize: function() {
      $('.region-content-row').css('background','url("images/new/thanks/imagen_gracias_denuncia.jpg") no-repeat center bottom');
      $('.region-content-row').css('background-size','cover');
      $.get("images/new/fondo-confirmreport.jpg").done(function () {
  			$('.region-content-row').css('background','url("images/new/fondo-confirmreport.jpg") no-repeat center');
  			$('.region-content-row').css('background-size','cover');
  		});
    },
    events: {
        'click #gobackbtn': 'showHome',
        'click #copycodebtn': 'copycode',
    },

    render: function() {
        this.attachEvents();
        var handlebarsTemplate = Handlebars.template(confirmreporttemplate);
        this.$el.html(handlebarsTemplate());
        window.spinnerDialog.hide();
        this.translate();
        setTimeout(function() {
          if(arrlang[lang]['NotifReportAnonymous']){
            $('#message').text(arrlang[lang]['NotifReportAnonymous']);
            $('#notif_report_anonymous').fadeIn('fast');  
            setTimeout(() => {
              $('#notif_report_anonymous').fadeOut();
            }, 5000);
          }
          else{
            $('#message').text('Recibirá notificaciones de su denuncia, siempre garantizando su anonimato');
            $('#notif_report_anonymous').fadeIn('fast');
            setTimeout(() => {
              $('#notif_report_anonymous').fadeOut();
            }, 5000);
          }
        }, 1000);
        return this;
    },

    copycode: function(e){
      var code = $('.code').text();
      navigator.clipboard.writeText(code);
      $('#copycodebtn').html(arrlang[lang]["CopiedBtn"]);
      cordova.plugins.clipboard.copy(code);
    },

     translate: function(e){
       var that = this;
       var callback = data => {
         if(arrlang[lang] === undefined){ return }
         that.$el.find('.item').each(function(index, element){
           $(this).html(arrlang[lang][$(this).attr("key")]);
         });
       }
       if(Object.keys(window.arrlang).length !== 0){
         callback();
       }
       this.listenTo(EventHub, 'translation', callback);
    },

    showHome: function() {
        EventHub.trigger('navigate', `login`, { trigger: true });
    },

    attachEvents: function() {},

});
export default confirmreportView;
