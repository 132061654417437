export default {"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.propertyIsEnumerable, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), alias5=container.hooks.helperMissing, alias6="function";

  return "<div id=\"containercustomformfile\" class=\"checkrequired\" key=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.options : depth0)) != null ? stack1.required : stack1), depth0))
    + "\">\r\n  <h1><label for=\"myfile\">"
    + alias3(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias5),(typeof helper === alias6 ? helper.call(alias4,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":2,"column":26},"end":{"line":2,"column":34}}}) : helper)))
    + "</label></h1>\r\n  <input multiple type=\"file\" key=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.options : depth0)) != null ? stack1.required : stack1), depth0))
    + "\" class=\"customformitem customupload\" id=\""
    + alias3(((helper = (helper = helpers.nametrim || (depth0 != null ? depth0.nametrim : depth0)) != null ? helper : alias5),(typeof helper === alias6 ? helper.call(alias4,{"name":"nametrim","hash":{},"data":data,"loc":{"start":{"line":3,"column":97},"end":{"line":3,"column":109}}}) : helper)))
    + "\" style=\"display:none;\" name=\""
    + alias3(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias5),(typeof helper === alias6 ? helper.call(alias4,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":3,"column":139},"end":{"line":3,"column":147}}}) : helper)))
    + "\" >\r\n  <div class=\"standar-button upload-container\" onclick=\"$('#"
    + alias3(((helper = (helper = helpers.nametrim || (depth0 != null ? depth0.nametrim : depth0)) != null ? helper : alias5),(typeof helper === alias6 ? helper.call(alias4,{"name":"nametrim","hash":{},"data":data,"loc":{"start":{"line":4,"column":60},"end":{"line":4,"column":72}}}) : helper)))
    + "').click();\"><div class=\"customuploada item\" key=\"AttachBtn\">Adjuntar archivo, imagen o video</div></div>\r\n  <div class=\""
    + alias3(((helper = (helper = helpers.nametrim || (depth0 != null ? depth0.nametrim : depth0)) != null ? helper : alias5),(typeof helper === alias6 ? helper.call(alias4,{"name":"nametrim","hash":{},"data":data,"loc":{"start":{"line":5,"column":14},"end":{"line":5,"column":26}}}) : helper)))
    + "\"></div>\r\n  <hr>\r\n</div>\r\n";
},"useData":true};