export default {"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias2,(helpers.currentlang||(depth0 && depth0.currentlang)||container.hooks.helperMissing).call(alias2,depth0,{"name":"currentlang","hash":{},"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":2,"column":26}}}),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":14,"column":9}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, alias3=container.propertyIsEnumerable;

  return "    <div class=\"radio-group\">\r\n      <label for=\"lang"
    + alias2(alias1(depth0, depth0))
    + "\">\r\n        <input type=\"radio\" id=\"lang"
    + alias2(alias1(depth0, depth0))
    + "\" name=\"langOption\" value=\""
    + alias2(alias1(depth0, depth0))
    + "\" class=\"langOption\" checked> "
    + alias2((helpers.langarray||(depth0 && depth0.langarray)||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"langarray","hash":{},"data":data,"loc":{"start":{"line":5,"column":109},"end":{"line":5,"column":127}}}))
    + "\r\n      </label>\r\n    </div>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, alias3=container.propertyIsEnumerable;

  return "    <div class=\"radio-group\">\r\n      <label for=\"lang"
    + alias2(alias1(depth0, depth0))
    + "\">\r\n        <input type=\"radio\" id=\"lang"
    + alias2(alias1(depth0, depth0))
    + "\" name=\"langOption\" value=\""
    + alias2(alias1(depth0, depth0))
    + "\" class=\"langOption\"> "
    + alias2((helpers.langarray||(depth0 && depth0.langarray)||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"langarray","hash":{},"data":data,"loc":{"start":{"line":11,"column":101},"end":{"line":11,"column":119}}}))
    + "\r\n      </label>\r\n    </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.langs : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":15,"column":9}}})) != null ? stack1 : "");
},"useData":true};