// Dependencies
import Backbone from 'backbone';
import Handlebars from 'handlebars';
import Settings from '~/settings.js';
import EventHub from '~/eventHub';
import linkify from 'linkify';
import linkifystring from 'linkifystring';
import linkifyjquery from 'linkifyjquery';

// Services
import getReportService from '~/services/report/reportService';
import getReportCommentsService from '~/services/report/reportCommentsService';
import getUtilsService from '~/services/common/utilsService';
import getPicturesService from '~/services/common/picturesService';
import getPushNotificationService from '~/services/common/pushNotificationService';
import getReportSuperCategoryService from '~/services/report/reportSuperCategoryService';
import getFileService from '~/services/common/fileService';
import getAlertService from '~/services/report/alertService';
import imageResize from '~/services/common/imageResizeService';

// Handlebars
import template from '~/templates/report/report.handlebars';
import report_template from '~/templates/report/report-details.handlebars';
import photo_swipe_template from '~/templates/report/photo-swipe.handlebars';
import report_commentaries from '~/templates/report/report-commentaries.handlebars';
import report_commentaries_thks from '~/templates/report/report-commentaries-thks.handlebars';
import customreportdetails_template from '~/templates/customformsfields/customreportdetails.handlebars';
import customreportfiles_template from '~/templates/customformsfields/customreportfile.handlebars';

var ReportView = Backbone.View.extend({
    tagName: "div",
    className: 'report-details-view',
    statusCollection: null,
    feedbackCollection: null,
    params: null,
    swipe_urls: [],
    ReportId: null,
    dataObj: null,
    status: null,
    opfeedback: null,
    varcomments: false,
    PicArray: [],
    CamArray:[],
    cont: null,
    report: null,
    filesx: null,

    initialize: function() {
      $('.region-content-row').css('background','url("images/new/fondo.jpg") no-repeat center');
      $('.region-content-row').css('background-size','cover');
      $.get("images/new/fondo-reportdetail.jpg").done(function () {
  			$('.region-content-row').css('background','url("images/new/fondo-reportdetail.jpg") no-repeat center');
  			$('.region-content-row').css('background-size','cover');
  		});
    },
    events: {
        'click .arrow': 'HideCommentary',
        'click #comment-img': 'AddComentary',
        'click .cancel-button': 'startReport',
        'change #upload': 'uploadImage',
        'click .button-indetreport': 'back',
        'keyup #report-comment': 'setenablebtn',
        'click .button-indetreport2': 'showthks',
        'click #imgload': 'loadimg',
        'click .comment-btn': 'savecomment',
        'click #gobackthks': 'goback-thks',
        'click .btnmodalcustomformdetails' : "showmmodalcustomform",
        'click #modalcustomform-cancel': "closecustomformModal",
        'click .reportdetailclosemodalicon': "closecustomformModal",
    },

    showmmodalcustomform: function(){
      $("#customformdetailsmodal").fadeIn();
    },

    closecustomformModal: function() {
      $("#customformdetailsmodal").fadeOut();
  	},

    gobackthks:function(){
      var reportid = this.ReportId;
      this.renderReport(reportid);
    },

    startReport: function() {
      EventHub.trigger('navigate', '/', {
          trigger: true
      });
    },

    translate: function(e){
      var that = this;
      var callback = data => {
        if(arrlang[lang] === undefined){ return }
        that.$el.find('.item').each(function(index, element){
          $(this).text(arrlang[lang][$(this).attr("key")]);
        });

        $('#textareacomment').attr("placeholder", arrlang[lang][$('#textareacomment').attr("key")]).val();
      }

      if(Object.keys(window.arrlang).length !== 0){
        callback();
      }
      this.listenTo(EventHub, 'translation', callback);
    },

    savecomment: function() {
        var comentario = this.$el.find('#textareacomment').val();
        var reportid = this.ReportId;
        this.renderReport(reportid);
    },

    async showthks() {
        window.spinnerDialog.show();
        var that = this;
        var comentario = this.$el.find('#textareacomment').val();
        var commentid = null;
        if(comentario == ""){
            alert("Para continuar tiene que escribir un comentario.")
			window.spinnerDialog.hide(); 
        }else{
            var reportid = this.ReportId;
            var reportpointer = { __type: "Pointer", className: "Report",objectId: reportid};
            commentid = await that.savecommentario(reportpointer, this.userid, comentario, []);
        }
            if (commentid != null) {
                if(that.file){
                    window.spinnerDialog.show("Subiendo Archivo");
                    getFileService().createSimpleFile(that.file,reportid,commentid, result=> {window.spinnerDialog.hide();});
                }
            }
            $('#addComentaryBox').fadeOut();
            $('#addComentaryThks').fadeIn();
		    window.spinnerDialog.hide();
      },

    async savecommentario (reportpointer, userid, comentario){
        return new Promise((resolve,reject) => {
            getReportCommentsService().setComment(reportpointer,userid, comentario,[], commentid=> {
                resolve(commentid.id);
            },error => {
                resolve(error);
                alert("Error al subir archivo, verifique su conexión a internet");
            });
        });
    },

    setenablebtn: function() {
        var comment = this.$el.find('#report-comment').val();
        if (comment.length > 0) {
            this.$el.find('.btn-sendcomment').css("color", "#28249e");
            this.$el.find('.btn-sendcomment').prop("disabled", false);
        }
        else {
            this.$el.find('.btn-sendcomment').css("color", "#cbcad7");
            this.$el.find('.btn-sendcomment').prop("disabled", true);
        }

    },
    render: function() {
        this.$el.html(Handlebars.template(template));
        this.$reportDisplay = this.$('.report_display');
        this.listenTo(EventHub, 'report:setState', data => {
            if (data.reportID) {
                this.renderReport(data.reportID);
            }
        });
        this.listenTo(EventHub, 'report:hide', data => {
            this.$el.addClass('hidden');
        });
        this.listenTo(EventHub, 'report:show', data => {
            this.$el.removeClass('hidden');
        });
        this.attachEvents();

        this.$el.find('#footer-menu').hide();
        this.$el.find('#images-container').hide();
        return this;
    },
    clearReport: function() {
        this.$reportDisplay.empty();
    },
    renderReport: function(reportID) {
        this.clearReport();
        this.ReportId = reportID;
        var id =  { reportID: reportID,mobile: true};
        Parse.Cloud.run("GetReport", id).then((reportinfo) => {
          if(reportinfo.report.length == 0){
            window.spinnerDialog.hide();
            alert(arrlang[lang]["NoReportFound"]);
            EventHub.trigger('navigate', 'login', {
                trigger: true
            });
          }
            var handlebarsTemplate = Handlebars.template(report_template);
            var that = this;
            var support = false;
            var simpledate = getUtilsService.getSimpleDate(reportinfo.report[0].attributes.createdAt);
            var options = {year: 'numeric',month: 'short',day: 'numeric',hour: 'numeric',minute: 'numeric'};
            var reportcreateddate = reportinfo.report[0].attributes.createdAt.toLocaleString('es-GB', options);
            var comments = {};
            comments.models = [];
            for (var i = 0; reportinfo.reportcomments && i < reportinfo.reportcomments.length; i++) {
                var commentstring =reportinfo.reportcomments[i].attributes.comment;
                var datecomment = reportinfo.reportcomments[i].attributes.createdAt ;
                var auditor = reportinfo.reportcomments[i].attributes.auditor;
                datecomment = datecomment.toLocaleString('es-GB', options);
                datecomment = datecomment.toUpperCase();
                var commentid = reportinfo.reportcomments[i].id;
                comments.models[i] = {attributes:{createdAt: datecomment, comment: commentstring, auditor: auditor, commentid: commentid}};
            }
            for (var i = 0; i < reportinfo.reportcomments.length; i++) {
                if (reportinfo.reportcomments[i].attributes.auditor != true) {
                    comments.models[i].userName = reportinfo.report[0].attributes.userName;
                }
            }
            var files = {};
            files.models = [];
            that.params = {
                reportcreatedat: reportcreateddate,
                id: reportID,
                attributes: reportinfo.report[0].attributes,
                comments: comments,
                days: simpledate,
                support: support,
                repfiles: files,
            };
            if(reportinfo.report[0].attributes.status == 'open'){
              that.params.open = true;
            }
            if(reportinfo.report[0].attributes.status == 'close'){
              that.params.close = true;
            }
            if(reportinfo.report[0].attributes.status == 'discard'){
              that.params.discard = true;
            }
            that.params.model = reportinfo.report;
            this.$reportDisplay.html(handlebarsTemplate(that.params));
            if (reportinfo.report[0].attributes.customreportvalues != undefined) {
              for (var i = 0; i < reportinfo.report[0].attributes.customreportvalues.customdata.length; i++) {
      					var type = reportinfo.report[0].attributes.customreportvalues.customdata[i].type;
      					var params2 = {name:reportinfo.report[0].attributes.customreportvalues.customdata[i].name,val:reportinfo.report[0].attributes.customreportvalues.customdata[i].val};
                var handlebarsTemplate = '';
                if (type == "file") {
      						handlebarsTemplate = Handlebars.template(customreportfiles_template);
      						var nametrime = params2.name.replace(/\s/g, '');
      						nametrime = nametrime.slice(0, -1);
      						params2.nametrim = nametrime;
      					}else{
      						handlebarsTemplate = Handlebars.template(customreportdetails_template);
      					}
      					$("#contentcustomreportdetails").append(handlebarsTemplate(params2));
      					this.renderfilescustomreport(params2, type);
      				}
            }else {
              $("#contentcustomreportdetails").html("<div class='reportdescription'><span>"+reportinfo.report[0].attributes.description+"</span><div class='customseparator'></div></div>");
            }

            for (var i = 0; i < reportinfo.reportfiles.length; i++) {
                var name = reportinfo.reportfiles[i].attributes.file._name;
                name = name.substr(32,name.length-32);
                var title = name.substr(32,name.length-32);
                var url = reportinfo.reportfiles[i].attributes.file._url;
                var content = "<button class='btn btn-default item file' data-url='"+url+"' title='"+title+"'>"+title+" </button>";
                var content2 = '<a href="'+url+'" onclick="cordova.InAppBrowser.open('+"'"+url+"'"+', '+"'_system'"+', '+"'location=yes'"+');" target="_blank" >'+name+"</a>";
                if (reportinfo.reportfiles[i].attributes.comment != undefined) {
        			var commentfilecontainer = "#comment-"+  reportinfo.reportfiles[i].attributes.comment.id ;
        			$(commentfilecontainer).append(content2);
        	    }else{
                  files.models[i] = reportinfo.reportfiles[i];
                  files.models[i].attributes.file._name = name;
        		}
            }
            var icon = reportinfo.report[0].attributes.subCategory.attributes.superCat.attributes.iconFile;
            var icon2 = '';
            if( icon != undefined){
                icon2 = reportinfo.report[0].attributes.subCategory.attributes.superCat.attributes.iconFile._url;
            }else{
                icon2 = "images/new/icons/7sO96C21Pf.svg";
            }
            $('#headerlogoreport').attr("src", icon2);
            this.translate();
			var tempReportDescrip = [];
			$('.report-description-field').text(function(index, content){
				var tempContent = linkifyStr(content);
				var tempStr = tempContent.split('<a ');
				var tempUrl = "";
				var tempUrlIndex = 0;
				if(tempStr.length > 1){
					var reconstructStr = tempStr[0];
					for(var i = 1; i < tempStr.length; ++i){
						if(tempStr[i].search('target="_blank">') != -1){
							if(!getUtilsService.getPlatform().browser){
								tempUrl = tempStr[i];
								tempUrl = tempUrl.replace('href="', "");
								tempUrlIndex = tempUrl.search('"');
								tempUrl = tempUrl.substring(0, tempUrlIndex);
								var click = 'cordova.InAppBrowser.open("'+tempUrl+'", "_system", "location=yes");';
								var newStr = "onclick='"+click+"'";
								tempStr[i] = tempStr[i].replace('href="'+tempUrl+'"', newStr);
							}
						}
						reconstructStr += '<a '+tempStr[i];
					}
					tempContent = reconstructStr;
				}
				tempReportDescrip.push(tempContent);
			});
			$('.report-description-field').html(function(index){
				return tempReportDescrip[index];
			});
			var tempArrayContent = [];
			$('.linkify_comment').text(function(index, content){
				var tempContent = linkifyStr(content);
				var tempStr = tempContent.split('<a ');
				var tempUrl = "";
				var tempUrlIndex = 0;
				if(tempStr.length > 1){
					var reconstructStr = tempStr[0];
					for(var i = 1; i < tempStr.length; ++i){
						if(tempStr[i].search('target="_blank">') != -1){
							if(!getUtilsService.getPlatform().browser){
								tempUrl = tempStr[i];
								tempUrl = tempUrl.replace('href="', "");
								tempUrlIndex = tempUrl.search('"');
								tempUrl = tempUrl.substring(0, tempUrlIndex);
								var click = 'cordova.InAppBrowser.open("'+tempUrl+'", "_system", "location=yes");';
								var newStr = "onclick='"+click+"'";
								tempStr[i] = tempStr[i].replace('href="'+tempUrl+'"', newStr);
							}
						}
						reconstructStr += '<a '+tempStr[i];
					}
					tempContent = reconstructStr;
				}
				tempArrayContent.push(tempContent);
			});
			$('.linkify_comment').html(function(index){
				return tempArrayContent[index];
			});
			$('#id-photo-swipe-container, #id-photo-mini-swipe-container').on('click', function(e) {
			var target = $(e.target);
            var url = target.attr('src');
            var selected_index = 0;
            var photoSwipeTemplate = Handlebars.template(photo_swipe_template);
            var images = $(this).children();
            $('.display_report').append(photoSwipeTemplate());
            var pswpElement = document.querySelectorAll('.pswp')[0];
            var items = [];
            var tw,th;
            for (var j = 0; j < images.length; j++) {
              tw = images[j].width*100;
              th = images[j].height*100;
                if (url == images[j].src) {
                    selected_index = j;

                }
                getFileService().fetch({
                    all: true,
                    cache: false,
                    rules: {
                        equals: [{
                            key: 'report',
                            val: {
                                __type: "Pointer",
                                className: "Report",
                                objectId: that.report.id
                            }
                        }]
                    }
                }).then(files => {
                    for (var i = 0; i < that.filesx.models.length; i++) {
                        var name = that.filesx.models[i].attributes.file._name;
                        name = name.substr(32,name.length-32);
                        that.filesx.models[i].attributes.file._name = name;
                    }
                    that.params = {
                        id: reportID,
                        attributes: report.models[0].attributes,
                        comments: comments,
                        days: simpledate,
                        support: support,
                        files: that.filesx,
                    };
                    if(report.models[0].attributes.status == 'open'){
                        that.params.open = true;
                    }
                    if(report.models[0].attributes.status == 'close'){
                        that.params.close = true;
                    }
                    if(report.models[0].attributes.status == 'discard'){
                        that.params.discard = true;
                    }
                    that.params.model = report.models[0];
                    this.$reportDisplay.html(handlebarsTemplate(that.params));;
                    this.translate();
					var tempReportDescrip = [];
					$('.report-description-field').text(function(index, content){
                        var tempContent = linkifyStr(content);
                        var tempStr = tempContent.split('<a ');
                        var tempUrl = "";
                        var tempUrlIndex = 0;
                        if(tempStr.length > 1){
                            var reconstructStr = tempStr[0];
                            for(var i = 1; i < tempStr.length; ++i){
                                if(tempStr[i].search('target="_blank">') != -1){
                                    if(!getUtilsService.getPlatform().browser){
                                        tempUrl = tempStr[i];
                                        tempUrl = tempUrl.replace('href="', "");
                                        tempUrlIndex = tempUrl.search('"');
                                        tempUrl = tempUrl.substring(0, tempUrlIndex);
                                        var click = 'cordova.InAppBrowser.open("'+tempUrl+'", "_system", "location=yes");';
                                        var newStr = "onclick='"+click+"'";
                                        tempStr[i] = tempStr[i].replace('href="'+tempUrl+'"', newStr);
                                    }
                                }
                                reconstructStr += '<a '+tempStr[i];
                            }
                            tempContent = reconstructStr;
                        }
                        tempReportDescrip.push(tempContent);
                    });
                    $('.report-description-field').html(function(index){
                        return tempReportDescrip[index];
                    });
                    var tempArrayContent = [];
                    $('.linkify_comment').text(function(index, content){
                        var tempContent = linkifyStr(content);
                        var tempStr = tempContent.split('<a ');
                        var tempUrl = "";
                        var tempUrlIndex = 0;
                        if(tempStr.length > 1){
                            var reconstructStr = tempStr[0];
                            for(var i = 1; i < tempStr.length; ++i){
                                if(tempStr[i].search('target="_blank">') != -1){
                                    if(!getUtilsService.getPlatform().browser){
                                        tempUrl = tempStr[i];
                                        tempUrl = tempUrl.replace('href="', "");
                                        tempUrlIndex = tempUrl.search('"');
                                        tempUrl = tempUrl.substring(0, tempUrlIndex);
                                        var click = 'cordova.InAppBrowser.open("'+tempUrl+'", "_system", "location=yes");';
                                        var newStr = "onclick='"+click+"'";
                                        tempStr[i] = tempStr[i].replace('href="'+tempUrl+'"', newStr);
                                    }
                                }
                                reconstructStr += '<a '+tempStr[i];
                            }
                            tempContent = reconstructStr;
                        }
                        tempArrayContent.push(tempContent);
                    });
                    $('.linkify_comment').html(function(index){
                        return tempArrayContent[index];
                    });
                    $('#id-photo-swipe-container, #id-photo-mini-swipe-container').on('click', function(e) {
                        var target = $(e.target);
                        var url = target.attr('src');
                        var selected_index = 0;
                        var photoSwipeTemplate = Handlebars.template(photo_swipe_template);
                        var images = $(this).children();
                        $('.display_report').append(photoSwipeTemplate());
                        var pswpElement = document.querySelectorAll('.pswp')[0];
                        var items = [];
                        var tw,th;
                        for (var j = 0; j < images.length; j++) {
                            tw = images[j].width*100;
                            th = images[j].height*100;
                            if (url == images[j].src) {
                                selected_index = j;
                            }
                            items.push({
                                src: images[j].src,
                                w:tw,
                                h:th
                            })
                        }
                        var options = {
                            useLargeImages: true,
                            focus: false,
                            history: false,
                            closeEl:true,
                            index: selected_index,
                            captionEl: false,
                            fullscreenEl: false,
                            zoomEl: true,
                            shareEl: false,
                            clickToCloseNonZoomable: false,
                            showAnimationDuration: 0.2,
                            hideAnimationDuration: 0.2
                        };
                        var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
                        gallery.init();
                    });
                    window.spinnerDialog.hide();
                    $('.addComentaryThks').css('background','url("images/new/thanks/imagen_gracias_comentario.jpg") no-repeat center');
                    $('.addComentaryThks').css('background-size','cover');
                    $('.display_report').css('background-color','rgba(0,0,0,0)');
                    $.get("images/new/fondo-confirmcomment.jpg").done(function () {
                			$('.addComentaryThks').css('background','url("images/new/fondo-confirmcomment.jpg") no-repeat center');
                			$('.addComentaryThks').css('background-size','cover');
                		});
                });
                items.push({
                    src: images[j].src,
                    w:tw,
                    h:th
                })
            }
            var options2 = {
                useLargeImages: true,
                focus: false,
                history: false,
                closeEl:true,
                index: selected_index,
                captionEl: false,
                fullscreenEl: false,
                zoomEl: true,
                shareEl: false,
                clickToCloseNonZoomable: false,
                showAnimationDuration: 0.2,
                hideAnimationDuration: 0.2
            };
            var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options2);
            gallery.init();
            });
            window.spinnerDialog.hide();
            $('.addComentaryThks').css('background','url("images/new/thanks/imagen_gracias_comentario.jpg") no-repeat center');
            $('.addComentaryThks').css('background-size','cover');
            $('.display_report').css('background-color','rgba(0,0,0,0)');
            $.get("images/new/fondo-confirmcomment.jpg").done(function () {
              $('.addComentaryThks').css('background','url("images/new/fondo-confirmcomment.jpg") no-repeat center');
              $('.addComentaryThks').css('background-size','cover');
            });
        })
    },

    renderfilescustomreport: function(params, type){
        if (type == 'file') {
            for (var i = 0; i < params.val.length; i++) {
                var extension = params.val[i].url.substring(params.val[i].url.length - 4, params.val[i].url.length);
                var title = '';
                var content = '';
                var url = params.val[i].url;
                if(extension == 'docx' || extension == ".doc" ){
                    var idtrime = params.val[i].url.replace(/[^A-Z0-9]/ig, "_");
                    idtrime = idtrime.slice(0, -5);
                    content = "<a class='download_link' href='"+url+"' download id='link-"+idtrime+"' target='_blank' ><button type='button'class='btn btn-default item file_download file' id='buttonlink-"+idtrime+"'>Archivo.docx</button></a>";
                    }
                else{
                    switch(extension) {
                        case ".png":
                            title = 'Imagen.png';
                        break;
                        case ".jpg":
                            title = 'Imagen.jpg'
                        break;
                        case ".pdf":
                            title = 'Archivo.pdf'
                        break;
                        case "jpeg":
                            title = 'Imagen.jpeg'
                        break;
                        case ".mp4":
                            title = 'Video.mp4'
                        break;
                        case ".PNG":
                            title = 'Imagen.png'
                        break;
                        default:
                            title = 'Archivo'
                        break;
                    }
                    content = "<a onclick='cordova.InAppBrowser.open('"+url+"', '_system', 'location=yes');' href='"+url+"' target='_blank'><button class='btn btn-default file_download item file' data-url='"+url+"' title='"+title+"'>"+title+" </button>";
                }
                $('#'+params.nametrim).append(content);
            }
        }
    },

    setCommentParts: function(reportID) {
        var that = this;
        getReportService().fetch({
            all: true,
            cache: false,
            rules: {
                equals: [{
                    key: 'objectId',
                    val: reportID
                }]
            }
        }).then(report => {
            var reportCommentariesTemplate = Handlebars.template(report_commentaries);
            var reportCommentariesThksTemplate = Handlebars.template(report_commentaries_thks);
            var params = {};
            $('.commentscont').hide();
            $('#addComentaryBox').fadeIn('fast');
            $('.addComentaryBox').html(reportCommentariesTemplate(params));
            $('.addComentaryBox').css('background-color','transparent');
            $('.addComentaryThks').html(reportCommentariesThksTemplate());
            this.translate();
            var icon = report.models[0].attributes.subCategory.attributes.superCat.attributes.iconFile;
            var icon2 = '';
            if( icon != undefined){
                icon2 = report.models[0].attributes.subCategory.attributes.superCat.attributes.iconFile._url;
            }else{
                icon2 = "images/new/icons/7sO96C21Pf.svg";
            }
            $('#commentheaderlogoreport').attr("src", icon2);
            window.spinnerDialog.hide();
        });
    },

    search: function(e) {
        var reportId = this.$('#reportID').val();
        if (reportId) {
            this.renderReport(reportId);
        }
        return false;
    },

    commentsOpenClose: function() {
        if (this.varcomments === false) {
            this.showcomments();
        }
        else {
            this.hidecoments();
        }
    },
    hidecoments: function() {
        this.$el.find('#togglecoments').slideUp('slow');
        this.varcomments = false;
    },
    showcomments: function() {
        this.$el.find('#togglecoments').slideDown('slow');
        this.varcomments = true;
    },

    attachEvents: function() {
        try {
            this.cont = 0;
            var that = this;
            this.listenTo(EventHub, 'report:details', data => {
                that.data = data;
                var categoryPromise = getReportCategoryService().fetch({
                    all: true,
                    cache: true,
                    rules: {
                        equals: [{
                            key: 'objectId',
                            val: that.data.category
                        }]
                    }
                });
                if (data.subcategory) {
                    alert('estoy en attachEvents');
                    var subCategoryPromise = getReportSubCategoryService().fetch({
                        all: true,
                        cache: true,
                        rules: {
                            equals: [{
                                key: 'objectId',
                                val: that.data.subcategory
                            }]
                        }
                    });
                    Promise.all([categoryPromise, subCategoryPromise]).then(([info, info2]) => {
                        that.data.categoryModel = info.models[0].parseObject;
                        that.data.subcategoryModel = info2.models[0].parseObject;

                        params.category = info2.models[0].attributes.name;
                        info2.models[0].parseObject.get('superCat').fetch().then(dat => {
                            params.supercategory = dat.attributes.name;
                        });
                    });
                }


            });
        }
        catch (e) {
        }
    },

    AddComentary: function(e) {
        this.setCommentParts(this.ReportId);
        this.PicArray = [];
        this.CamArray = [];
    },

    HideCommentary: function(e) {
		window.spinnerDialog.show("Subiendo Archivo");
		$('#addComentaryBox').fadeOut();
        $('.commentscont').fadeIn('fast');
		window.spinnerDialog.hide();
    },

    uploadImage: function(e) {
		var that = this;
		if (e.target.files[0].size < 80000000) {
            window.spinnerDialog.show("Escaneando archivo");
            var namelenght = e.target.files[0].name.length;
    		var extension = e.target.files[0].name.substring(namelenght-4, namelenght);
    		if (extension.charAt(0) != '.'){
    			var extension = e.target.files[0].name.substring(namelenght-5, namelenght);
    		}
    		var reader = new FileReader();
    		var name = 'File_'+extension;
    		$('.upload').html(name);
    		var checkResize = imageResize.checkImage(extension);
    		if(checkResize){
    			var returnFunc = function(data){
    				var b64Data = data.split('base64,')[1];
    				var parseFile = new Parse.File(name, { base64: b64Data }, 'image/png');
    				that.file =  parseFile;
    				window.spinnerDialog.hide();
    			};
    			reader.onload = function (e) {
    				imageResize.resizeImage(e.target.result, returnFunc);
    			};
    			reader.readAsDataURL(e.target.files[0]);
    		}
    		else{
    			reader.onload = function (e) {
    				var byteArray = new Uint8Array(e.target.result);
    				var output = new Array(byteArray.length);
    				var i = 0;
    				var n = output.length;
    				while (i < n) {
    					output[i] = byteArray[i];
    					i++;
    				}
					switch(extension){
						case '.pdf':
							var parseFile = new Parse.File(name, output, 'application/pdf');
						break;
						default:
							var parseFile = new Parse.File(name, output);
						break;
					}
    				that.file =  parseFile;
    				window.spinnerDialog.hide();
    			};
    			reader.readAsArrayBuffer(e.target.files[0]);
    		}
        }else{
            alert(arrlang[lang]['Filetolarge']);
            $('.upload').html(arrlang[lang]['AttachBtn']);
        }
    },

    Elegirfoto: function() {
        $('#insertPic').modal('hide');
        this.$el.find('#imgload').click();
    },

    back: function() {
        this.HideCommentary();
    },
    save: function() {
        this.data.description = $('.description').val();
    },


});
export default ReportView;
