export default {"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable, alias2=container.lambda, alias3=container.escapeExpression;

  return "		       <div class=\"normal-category category btnitem\" id=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.id : stack1), depth0))
    + "\">\r\n		       "
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.name : stack1), depth0))
    + "\r\n		    	</div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable;

  return "<div class=\"programa col-sm-12 col-sx-12 \">\r\n		<div class=\"main-container\">\r\n			<img class=\"app-img\" src=\"images/new/shogun_logo_512x512.png\">\r\n			<div class=\"tittle-container\">\r\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.program : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":5},"end":{"line":9,"column":14}}})) != null ? stack1 : "")
    + "				<br>\r\n				<div class=\"btnhome\"><div class=\"item\" key=\"Btn_home\">Inicio</div></div>\r\n				<br><br>\r\n			</div>\r\n		</div>\r\n</div>\r\n";
},"useData":true};