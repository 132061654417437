export default {"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.propertyIsEnumerable, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function";

  return "<div id=\"containercustomsmalltext\" class=\"checkrequired\" key=\""
    + alias2(container.lambda(((stack1 = (depth0 != null ? depth0.options : depth0)) != null ? stack1.required : stack1), depth0))
    + "\">\r\n    <h1 class=\"customsmalltexttittle\" key=\"\" id=\"\">\r\n        "
    + alias2(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":16}}}) : helper)))
    + "\r\n    </h1>\r\n    <div class=\"customsmalltextfieldcontainer\">\r\n      <textarea type=\"smalltext\" name=\""
    + alias2(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":6,"column":39},"end":{"line":6,"column":47}}}) : helper)))
    + "\" class=\"customsmalltextfield customformitem\" rows=\"8\" cols=\"50\" placeholder=\""
    + alias2(((helper = (helper = helpers.placeholder || (depth0 != null ? depth0.placeholder : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"placeholder","hash":{},"data":data,"loc":{"start":{"line":6,"column":125},"end":{"line":6,"column":140}}}) : helper)))
    + "\"></textarea>\r\n    </div>\r\n    <hr>\r\n</div>\r\n";
},"useData":true};