import Backbone from 'backbone';

import ParseService from '~/services/common/parseService';
import Model from '~/models/parseModel';
import Collection from '~/collections/parseCollection';

var reportService;
export default function() { // lazy initialization. we export function which returns servies. this way service will be created only when it will be really necessary

    // model
    var ReportModel = Model.extend({
        className: "Report",
        defaults: {
            'manualTitle': false,
        },
        // here come the report methods.

        checkIconType: function() {
            // this function returns the icon type.
            //return: 'SVG' or 'ion'
            var thisReportType = '';
            var thisReportCategory = app.categoryCollection.get(this.attributes.category.id);
            if (thisReportCategory) {
                var reportIconRaw = thisReportCategory.attributes.icon;
                if (reportIconRaw.substring(2, 5) == 'xml') {
                    // SVG icon
                    thisReportType = 'SVG';
                } else if (reportIconRaw.substring(0, 3) == 'ion') {
                    // ion icon.
                    thisReportType = 'ion';
                }
            } else {
                // nothing.
            }
            this._type = thisReportType;
            return thisReportType;
        }, // end checkIconType

        fetchAssigneeHistory: function() {
            var report = this;
            return Promise.resolve().then(function() {
                var query = new Parse.Query('ReportAssignee');
                query.equalTo("report", report.toPointer());
                query.descending("createdAt");
                return query.find();
            }).then(function(assignees) {
                report.assigneeHistory = assignees;
                return Promise.resolve(report.assigneeHistory);
            });
        }
    });

    // collection
    var ReportsCollection = Collection.extend({
        model: ReportModel,
        doCountByCategory: function(categoriesCollection) {
            var countedByCategory = {};
            countedByCategory.raw = {};
            var that = this;
            _.each(this.models, function(thisModel) {
                if (thisModel) {
                    if (thisModel.attributes) {
                        if (thisModel.attributes.category) {
                            var thisCategoryID = thisModel.attributes.category.id;
                        }
                    }
                }
                if (_.has(countedByCategory.raw, thisCategoryID)) {
                    // enters here if this date/day was already registered.
                    countedByCategory.raw[thisCategoryID].count += 1;
                } else {
                    //this date was not registered yet
                    var newObject = {
                        count: 1
                    };
                    countedByCategory.raw[thisCategoryID] = newObject;
                }
            });
            countedByCategory.plotData = {};
            countedByCategory.plotData.X = [];
            countedByCategory.plotData.Y = [];
            countedByCategory.plotData.ID = [];
            countedByCategory.plotData.color = [];
            _.each(categoriesCollection.models, function(thisCategoryModel) {
                if (countedByCategory.raw[thisCategoryModel.id]) {
                    countedByCategory.plotData.Y.push(countedByCategory.raw[thisCategoryModel.id].count);
                    countedByCategory.plotData.X.push(thisCategoryModel.id);
                    countedByCategory.plotData.ID.push(thisCategoryModel.id);
                    countedByCategory.plotData.color.push(thisCategoryModel.attributes.color);
                }
            });
            this.countedByCategory = countedByCategory;
        }
    });

    // service (extends ParseService)
    var ReportService = ParseService.extend({
        sortBy: 'createdAt',
        itemsOnPage: 100, // load by N items, this overrides the 1k limit 'parseService.js'
        model: ReportModel,
        collection: ReportsCollection,
        
        createReport: function(data, success){
          var model = new this.model("Report");
          model.set('user',Parse.User.current());
          model.set('description',data.description);
          model.set('userName',data.username);
          model.set('category',data.categoryModel);
          if (data.provincia != undefined) {
            model.set('provincia',data.provincia);
          }
          if (data.canton != '') {
            if (data.canton != 'Seleccione cantón') {
                model.set('canton',data.canton);
            }else{
                model.set('canton','0');
            }
          }
          if (data.reportplace != '') {
            model.set('comercio',data.reportplace);
          }
          if(data.subcategory){
            model.set('subCategory',data.subcategory)//model
          }
          if(data.subcategoryModel){
            model.set('subCategory',data.subcategoryModel)//model
          }
          if (data.customreportvalues) {
            model.set('customreportvalues', data.customreportvalues);
          }
          return model.save(null,{success: success});
        },
    });

    return reportService || (reportService = new ReportService());
};
// only created once for application
