import Parse from 'parse';

function ConfigService() {}
ConfigService.prototype = {
	initialize: function() {
		
	},
	
	getConfig: function () {
		return Parse.Config.get();
	}
}
export default new ConfigService();
