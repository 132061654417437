// Dependencies
import Backbone from 'backbone';
import Handlebars from 'handlebars';
import EventHub from '~/eventHub';

// Services
import getNewsService from '~/services/report/newsService';

// Handlebars
import template from '~/templates/report/singlenews.handlebars';
import photo_swipe_template from '~/templates/report/photo-swipe.handlebars';

var SinglenewsView = Backbone.View.extend({
	className: 'singlenewsView',
	initialize: function() {
		$('.region-content-row').css('background','url("images/new/fondo.jpg") no-repeat center');
		$('.region-content-row').css('background-size','cover');
		$.get("images/new/fondo-singlenew.jpg").done(function () {
			$('.region-content-row').css('background','url("images/new/fondo-singlenew.jpg") no-repeat center');
			$('.region-content-row').css('background-size','cover');
		});
	},
	events: {
		'click .btnhome': 'home',
		'click .btnback': 'back',
		'click .singlenews-img': 'openPhotoSwipe'
	},

	translate: function(e){
		var that = this;
		var callback = data => {
			if(arrlang[lang] === undefined){ return }
			that.$el.find('.item').each(function(index, element){
				$(this).text(arrlang[lang][$(this).attr("key")]);
			});
		}

		if(Object.keys(window.arrlang).length !== 0){
			callback();
		}
		this.listenTo(EventHub, 'translation', callback);
	},

	render: function() {
		var handlebarsTemplate = Handlebars.template(template);
		this.$el.html(handlebarsTemplate());
		this.listenTo(EventHub, 'singlenews:setState', data => {
			if (data.singlenewsID) {
				this.renderSinglenews(data.singlenewsID);
			}
		});
		this.translate();
		return this;
	},

	renderSinglenews: function(singlenewsID){
		var newsPromise = getNewsService().fetch({
			all: true,
			cache: false,
			rules: {
				equals: [{
					key: 'objectId',
					val: singlenewsID
				}]
			}
		}).then(singleNews => {
			var handlebarsTemplate = Handlebars.template(template);
			if(localStorage.newsCodes){
				if(localStorage.newsCodes.indexOf(singlenewsID) == -1){
					localStorage.newsCodes += singlenewsID+'-';
				}
			}
			else{
				localStorage.newsCodes = ''+singlenewsID+'-';
			}
			var singleNewsText = '';
			var date = new Date(singleNews.models[0].attributes.createdAt);
			var options = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true};
			var localDate = date.toLocaleString('es', options);
			singleNewsText = '<div class="info"><p class="date">'+localDate+'</p><p class="name">'+singleNews.models[0].attributes.title+'</p><p class="content">'+singleNews.models[0].attributes.body+'</p>';
			if(singleNews.models[0].attributes.File != null){
				singleNewsText += '<img class="singlenews-img" src="'+singleNews.models[0].attributes.File._url+'">';
			}
			if(singleNews.models[0].attributes.newsLink != ""){
				var click = 'cordova.InAppBrowser.open("'+singleNews.models[0].attributes.newsLink+'", "_system", "location=yes");';
				singleNewsText += "<center><div class='push-link item' key='CheckAttachedFile' onclick='"+click+"'>Ver enlace adjunto</div></center>";
			}
			singleNewsText += "</div>";
			window.spinnerDialog.hide();
			this.$el.html(handlebarsTemplate());
			this.$el.find('#singlenews').html(singleNewsText);
			this.translate();
		});
	},

	home: function() {
		EventHub.trigger('navigate', 'login', {
			trigger: true
		});
	},

	back: function(){
		EventHub.trigger('navigate', 'news', {
			trigger: true
		});
	},

	openPhotoSwipe: function(e){
		var photoSwipeTemplate = Handlebars.template(photo_swipe_template);
		$('.singlenewsView').append(photoSwipeTemplate());
		var pswpElement = document.querySelectorAll('.pswp')[0];
		var items = [];
		items.push({
			src: e.currentTarget.src,
			w:e.currentTarget.width,
			h:e.currentTarget.height
		});
		var options = {
			useLargeImages: true,
			focus: false,
			history: false,
			closeEl:true,
			index: 0,
			captionEl: false,
			fullscreenEl: false,
			zoomEl: true,
			shareEl: false,
			clickToCloseNonZoomable: false,
			showAnimationDuration: 0.2,
			hideAnimationDuration: 0.2
		};
		var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
		gallery.init();
	}
});
export default SinglenewsView;
