// Dependencies
import Backbone from 'backbone';
import Handlebars from 'handlebars';
import EventHub from '~/eventHub';
import Settings from '~/settings.js';
import linkify from 'linkify';
import linkifystring from 'linkifystring';
import linkifyjquery from 'linkifyjquery';

// Services
import getUserService from '~/services/user/userService';
import getUtilsService from '~/services/common/utilsService';
import getPushNotificationService from '~/services/common/pushNotificationService';
import getAlertService from '~/services/report/alertService';
import getNewsService from '~/services/report/newsService';
import getConfigService from '~/services/common/configService';

// Handlebars
import template from '~/templates/common/login.handlebars';
import langtemplate from '~/templates/utils/languageOption.handlebars';

Handlebars.registerHelper("langarray", function (key) {
	var entry = window.arrlang[key]["LanguageName"];
	if(entry === undefined){
		return "Default";
	}
  return entry;
})

Handlebars.registerHelper("currentlang", function (key) {
  return lang === key ? true : false;
})

var LoginView = Backbone.View.extend({
	className: 'loginView',
	initialize: function() {
		$('.region-content-row').css('background','url("images/new/fondo.jpg") no-repeat center');
		$('.region-content-row').css('background-size','cover');
		$.get("images/new/fondo-login.jpg").done(function () {
			$('.region-content-row').css('background','url("images/new/fondo-login.jpg") no-repeat center');
			$('.region-content-row').css('background-size','cover');
		});
	},
	codes: [],
	events: {
		'submit .form-log': 'login',
		'submit #signin-pass': 'showstartq',
		'click #showloginw': 'showLogin',
		'click #showstart': 'showstartq',
		'click .btnmisdenuncias': 'showstartqModal',
		'click #modal-accept': 'showLoginModal',
		'click [data-action="logout"]': 'logout',
		'click .view-password': 'viewpassword',
		'focus .form-field-password': 'focuspassword',
		'focus .form-field': 'focusemail',
		'keyup .form-field-password': 'enablebtnlogin',
		'click .btnnewuser': 'showregister',
		'click #skiplogin': 'skipLogin',
		'click #modal-cancel': 'closeModal',
		'click .item-click': 'gotoReport',
		'click .delete': 'removeReport',
		'click .btnconferencistas': 'showconferencistas',
		'click .btnconferencistas2': 'showconferencistas2',
		'click .btnprograma': 'showprograma',
		'click .btnprogramas': 'showprogramas',
		'click .btnpatrocinadores': 'showPatrocinadores',
		'click .btnnews': 'showNews',
		'click #gotosearch': 'gotosearch',
		'click .langOption': 'changeLanguage',
		'click #close-localization': 'closeLocalization',
		'click #localization-button': 'openLocalization',
		'click .openmodaltermsandcons' : 'openmodaltermsandcons',
		'click #modaltermsandconsform-cancel' : 'closemodaltermsandcons',
		'click .closemodalicon' : 'closemodaltermsandcons',
  },

  openmodaltermsandcons: function(e){
	const temrandcns = Parse.Config.current().get("termsandcons");
		$(".termsandconstext").html(temrandcns.esp)
		$("#customformtermsandcons").fadeIn();
  },

  closemodaltermsandcons: function() {
	$("#customformtermsandcons").fadeOut();
  },

  translate: function(e){
	var that = this;
	var callback = data => {
		if(arrlang[lang] === undefined){ return }
		that.$el.find('.item').each(function(index, element){
			if ($(this).attr("key") != "Placeholder-home") {
				$(this).html(arrlang[lang][$(this).attr("key")]);
			}
		});

		that.$el.find('.start-input').attr('placeholder',arrlang[lang]["Placeholder-home"]);
		that.$el.find('#localization-button span').html("("+lang+")");

		var langOptionTemplate = Handlebars.template(langtemplate);
		that.$el.find('#selectLanguage').html(langOptionTemplate({ langs: Object.keys(arrlang) }));
	}

	if(Object.keys(window.arrlang).length !== 0){
		callback();
	}
	this.listenTo(EventHub, 'translation', callback);
  },

	changeLanguage: function(e) {
		window.lang = e.target.value;
		localStorage.setItem("lang", window.lang);
		this.closeLocalization();
		this.translate();
	},

	openLocalization: function(){
		this.$el.find('#localization-modal').fadeIn('fast');
	},

	closeLocalization: function(){
		this.$el.find('#localization-modal').fadeOut('fast');
	},

	removeReport: function(obj){
		window.localStorage.codes = "";
		for(var i=0;i<this.codes.length;++i){
			if(i!=obj.currentTarget.id){
				window.localStorage.codes += this.codes[i]+"-";
			}
		}
		window.localStorage.codes = localStorage.codes.substring(0,localStorage.codes.length-1);
		this.render();
	},

	render: function() {
		window.spinnerDialog.hide();
		var templatex = Handlebars.template(template);
		var newsPromise = getNewsService().fetch({
			all: true,
			cache: false
		}).then(news => {
			if(news.models.length > 0){
				if(localStorage.newsTotal){
					if(localStorage.newsCodes){
						var newsCodes = localStorage.newsCodes.split("-");
						newsCodes.pop();
						if(newsCodes.length < localStorage.newsTotal){
							$('#alert_news').show();
						}
					}
					else{
						$('#alert_news').show();
					}
				}
				else{
					$('#alert_news').show();
				}
			}
		});
		var btnGeneral = function(tempLink, tempText){
			$('.btngeneral').show();
			$('.btngeneral').text(tempLink);
			var optionsLinkify = {
				format: function (value, type) {
					if (type === 'url') {
						value = tempText
					}
					return value;
				}
			};
			var tempGeneralButton = [];
			$('.btngeneral').text(function(index, content){
				var tempContent = linkifyStr(content, optionsLinkify);
				var tempStr = tempContent.split('<a ');
				var tempUrl = "";
				var tempUrlIndex = 0;
				if(tempStr.length > 1){
					var reconstructStr = tempStr[0];
					for(var i = 1; i < tempStr.length; ++i){
						if(tempStr[i].search('target="_blank">') != -1){
							if(!getUtilsService.getPlatform().browser){
								tempUrl = tempStr[i];
								tempUrl = tempUrl.replace('href="', "");
								tempUrlIndex = tempUrl.search('"');
								tempUrl = tempUrl.substring(0, tempUrlIndex);
								var click = 'cordova.InAppBrowser.open("'+tempUrl+'", "_system", "location=yes");';
								var newStr = "onclick='"+click+"'";
								tempStr[i] = tempStr[i].replace('href="'+tempUrl+'"', newStr);
							}
						}
						reconstructStr += '<a '+tempStr[i];
					}
					tempContent = reconstructStr;
				}
				tempGeneralButton.push(tempContent);
			});
			$('.btngeneral').html(function(index){
				return tempGeneralButton[index];
			});
		};
		var middle = function(){
			if(localStorage.linkInfo && localStorage.linkInfo != ''){
				var tempInfo = localStorage.linkInfo.split('<');
				var tempLink = tempInfo[0];
				var tempText = tempInfo[1];
				btnGeneral(tempLink, tempText);
			}
		};
		getConfigService.getConfig().then(config => {
			var linkInfo = config.get('linkInfo');
			localStorage.linkInfo = ''+linkInfo[0]+'<'+linkInfo[1];
			middle();
		},
		error => {
			console.log(error);
			middle();
		});
		if(localStorage.codes){
			this.codes = localStorage.codes.split("-");
			var codeText = "";
			for(var i=0; i< this.codes.length-1; ++i){
				var data = this.codes[i].split("&");
				codeText = '<div class="item-click" id="'+data[0]+'"><i id="alert'+data[0]+'" style="display:none;" class="fa-exclamation-circle fa fa-lg"> </i> '+data[2]+" "+data[1]+' </div><i id="'+i+'" class="delete fa fa-trash fa-lg"></i>'+codeText;
				getAlertService().fetch({
					all: true,
					cache: false,
					rules: {
						equals: [{
							key: 'report',
							val:  { __type: "Pointer", className: "Report", objectId: data[0] }
						}]
					}
				}).then(report => {
					for(var i=0; i<report.models.length;++i){
						if(report.models[i].attributes.alert){
							$('#alert').show();
							$('#alert'+report.models[i].attributes.report.id).show();
						}
					}
				});
			}
			this.$el.html(templatex({platform: getUtilsService.getPlatform(), mobile: !getUtilsService.getPlatform().browser, policy: Settings.privacityPoliticsURL }));
			this.$el.find('#codes').html(codeText);
			this.translate();
		}
		else {
			this.$el.html(templatex({platform: getUtilsService.getPlatform(), mobile: !getUtilsService.getPlatform().browser, codes : [], policy: Settings.privacityPoliticsURL}));
			this.translate();
		}
		localStorage.searchx = '';
		return this;
	},

	showregister: function(){
		var id = $('.start-input').val();
		if(id){
			EventHub.trigger('navigate', 'report', { trigger: true });
			EventHub.trigger('report:setState', { reportID: id });
		}
	},

	gotoReport:function(e){
		var id = $(e.currentTarget).attr("id");
		if(id){
			EventHub.trigger('navigate', 'report', { trigger: true });
			EventHub.trigger('report:setState', { reportID: id });
		}
	},

	skipLogin:function(){
		localStorage.searchx = false;
		Settings.login = true;
		EventHub.trigger('navigate', 'home', { trigger: true });
	},


	gotosearch: function(){
		Settings.login = true;
		EventHub.trigger('navigate', 'search', { trigger: true });
	},


	showLogin: function() {
		this.$el.find("#showloginw").css("text-decoration", "none");
		this.$el.find(".login").hide();
		this.$el.find('#start').hide();
		this.$el.find('#form-login').fadeIn();
		this.$el.find('#email').focus();
	},

	showstartq: function() {
		this.$el.find('#form-login').hide();
		this.$el.find('.login').fadeIn();
	},

	showLoginModal: function() {
		this.$el.find('#start').fadeOut('fast');
		this.$el.find('#form-login').fadeIn('fast');
		document.getElementById("form-logi").reset();
	},

	showstartqModal: function() {
		this.$el.find('#login-error-modal').fadeIn('fast');
	},

	closeModal: function() {
		this.$el.find('#login-error-modal').fadeOut();
	},

	showprograma: function(){
		Settings.login = true;
		EventHub.trigger('navigate', 'programa', { trigger: true });
	},

	showprogramas: function(){
		Settings.login = true;
		EventHub.trigger('navigate', 'programas', { trigger: true });
	},

	showPatrocinadores: function(){
		Settings.login = true;
		EventHub.trigger('navigate', 'sponsors', { trigger: true });
	},

	showconferencistas: function(){
		Settings.login = true;
		EventHub.trigger('navigate', 'conferencistas', { trigger: true });
	},

	showContainer: function(){
		Settings.login = true;
		EventHub.trigger('navigate', 'container', { trigger: true });
	},

	showconferencistas2: function(){
		Settings.login = true;
		EventHub.trigger('navigate', 'informacioncategorias', { trigger: true });
	},

	showNews: function(){
		Settings.login = true;
		EventHub.trigger('navigate', 'news', { trigger: true });
	},

	showpregunta:function(){
		Settings.login = true;
		EventHub.trigger('navigate', 'pregunta', { trigger: true });
	},

	showpregunta:function(){
		Settings.login = true;
		EventHub.trigger('navigate', 'pregunta', { trigger: true });
	},

	showpregunta:function(){
		Settings.login = true;
		EventHub.trigger('navigate', 'pregunta', { trigger: true });
	},
});
export default LoginView;
