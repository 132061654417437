// Dependencies
import Backbone from 'backbone';
import Handlebars from 'handlebars';
import EventHub from '~/eventHub';

// Services
import getReportSubCategoryService from '~/services/report/reportSubCategoryService';

// Handlebars
import template from '~/templates/report/conferencistas.handlebars';

var InformacionsubcategoriasView = Backbone.View.extend({
    className: 'conferencistasView',

    initialize: function() {
		$('.region-content-row').css('background','url("images/new/fondo.jpg") no-repeat center');
		$('.region-content-row').css('background-size','cover');
    $.get("images/new/fondo-informacionsubcategorias.jpg").done(function () {
			$('.region-content-row').css('background','url("images/new/fondo-informacionsubcategorias.jpg") no-repeat center');
			$('.region-content-row').css('background-size','cover');
		});
	},

    events: {
        'click .btnhome': 'home',
		    'click .conferencista': 'gotoConferencista',
        'click .btnconferencistasback' : 'goback',
    },

     translate: function(e){
       var that = this;
       var callback = data => {
         if(arrlang[lang] === undefined){ return }
         that.$el.find('.item').each(function(index, element){
           $(this).text(arrlang[lang][$(this).attr("key")]);
         });
       }
       if(Object.keys(window.arrlang).length !== 0){
         callback();
       }
       this.listenTo(EventHub, 'translation', callback);
    },

	render: function() {
    this.listenTo(EventHub, 'conferencista:setState', data => {
      if (data.categoriaID) {
        this.renderConferencistas(data.categoriaID);
      }
    });
	  var handlebarsTemplate = Handlebars.template(template);
      this.$el.html(handlebarsTemplate());
      this.translate();
	  return this;
    },

    renderConferencistas: function(categoriaID) {
		var superCategoryPromise = getReportSubCategoryService().fetch({
        all: true,
        cache: true,
        rules: {
          equals: [{
              key: 'superCat',
              val: { __type: "Pointer", className: "superCategory", objectId: categoriaID }
          }]
        }
        }).then(conferencistas => {
			var handlebarsTemplate = Handlebars.template(template);
			var listText = "";
            for(var i=0; i< conferencistas.models.length; ++i){
			  conferencistas.models[i].attributes.name = conferencistas.models[i].attributes.name.replace(/\n/g, "<br>");
        var image = "";
        if(conferencistas.models[i].attributes.image){
          image = conferencistas.models[i].attributes.image._url;
        }
        if(conferencistas.models[i].attributes.active){
			   listText += '<div class="conferencista" id="'+conferencistas.models[i].id+'"><img class="conferencista-img" src="'+image+'"><p class="name">'+conferencistas.models[i].attributes.name+'</p></div>';
        }
            }
			window.spinnerDialog.hide();
            this.$el.html(handlebarsTemplate());
            this.translate();
            var clientName = arrlang[lang]["ClientName"];
            if (clientName !== undefined && clientName !== null && clientName.toUpperCase() === 'CAPACITA') {
              $('#tittlecapacita').text(conferencistas.models[0].attributes.superCat.attributes.name);
            }
			this.$el.find('#list').html(listText);
        });
    },

	home: function() {
        EventHub.trigger('navigate', 'login', {
            trigger: true
        });
    },

  goback: function(){
    EventHub.trigger('navigate', 'informacioncategorias', { trigger: true });
  },

	gotoConferencista: function(e){
		var id = $(e.currentTarget).attr("id");
		if(id){
			EventHub.trigger('navigate', 'informacionsubconferencista/'+id, { trigger: true });
		}
	}
});
export default InformacionsubcategoriasView;
