import Backbone from 'backbone';

import ParseService from '~/services/common/parseService';
import Model from '~/models/parseModel';
import Collection from '~/collections/parseCollection';

var reportCommentsService;
export default function() {

    // model
    var ReportCommentsModel = Model.extend({
        className: "ReportComments",
        defaults: {
            'manualTitle': false,
        }
    });

    // collection
    var ReportCommentCollection = Collection.extend({
        model: ReportCommentsModel
    });

    // service (extends ParseService)
    var ReportCommentsService = ParseService.extend({
        sortBy: 'createdAt',
        order: 'dsc',
        model: ReportCommentsModel,
        collection: ReportCommentCollection,
        // you can also extend any method from ParseService here
        setComment: function(reportID, user, commentario,pictures, success, error) {
            var ReportComment = Parse.Object.extend("ReportComments");
            var comment = new ReportComment();
            comment.set("report", reportID);
            comment.set("user", user);
            comment.set("comment", commentario);
            comment.set("pictures",pictures);
            comment.save().then((succ)=>{
                success(succ);
                },(err)=>{
                error(err);
            });
        }
    });

    return reportCommentsService || (reportCommentsService = new ReportCommentsService());
};
// only created once for application
