import Backbone from 'backbone';

import ParseService from '~/services/common/parseService';
import Model from '~/models/parseModel';
import Collection from '~/collections/parseCollection';

var programService;
export default function() { // lazy initialization. we export function which returns servies. this way service will be created only when it will be really necessary

    // model
    var InformationModel = Model.extend({
        className: "Program",
        defaults: {
            'manualTitle': false,
        },

    });

    // collection
    var InformationCollection = Collection.extend({
        model: InformationModel
    });

    // service (extends ParseService)
    var ProgramService = ParseService.extend({
        sortBy: 'priority',
        model: InformationModel,
        collection: InformationCollection,
        // you can also extend any method from ParseService here
    });

    return programService || (programService = new ProgramService());
};
// only created once for application
