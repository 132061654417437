// Dependencies
import Backbone from 'backbone';
import Handlebars from 'handlebars';
import EventHub from '~/eventHub';
import getUtilsService from '~/services/common/utilsService';

// Services
import getReportSubCategoryService from '~/services/report/reportSubCategoryService';

// Handlebars
import template from '~/templates/report/conferencista.handlebars';

var InformacionsubconferencistaView = Backbone.View.extend({
    className: 'conferencistaView',
    
    initialize: function() {
      $('.region-content-row').css('background','url("images/new/fondo.jpg") no-repeat center');
      $('.region-content-row').css('background-size','cover');
      $.get("images/new/fondo-informacionsubconferencista.jpg").done(function () {
  			$('.region-content-row').css('background','url("images/new/fondo-informacionsubconferencista.jpg") no-repeat center');
  			$('.region-content-row').css('background-size','cover');
  		});
    },

    events: {
        'click .btnhome': 'home',
		  'click .btnback': 'back'
    },

     translate: function(e){
       var that = this;
       var callback = data => {
         if(arrlang[lang] === undefined){ return }
         that.$el.find('.item').each(function(index, element){
           $(this).text(arrlang[lang][$(this).attr("key")]);
         });
       }
       if(Object.keys(window.arrlang).length !== 0){
         callback();
       }
       this.listenTo(EventHub, 'translation', callback);
    },

    render: function() {
		var handlebarsTemplate = Handlebars.template(template);
		this.$el.html(handlebarsTemplate());
		this.listenTo(EventHub, 'conferencista:setState', data => {
			if (data.conferencistaID) {
				this.renderConferencista(data.conferencistaID);
			}
		});
      this.translate();
      return this;
    },

	renderConferencista: function(conferencistaID){
    var platform = getUtilsService.getPlatform();
		var superCategoryPromise = getReportSubCategoryService().fetch({
            all: true,
            cache: false,
			      rules: {
              equals: [{
                  key: 'objectId',
                  val: conferencistaID
              }]
            }
        }).then(conferencista => {
			var handlebarsTemplate = Handlebars.template(template);
			window.spinnerDialog.hide();
			conferencista.models[0].attributes.name = conferencista.models[0].attributes.name.replace(/\n/g, "<br>");
      conferencista.models[0].attributes.description = conferencista.models[0].attributes.description.replace(/\n/g, "<br>");
      if (platform.ios) {
        conferencista.models[0].attributes.description = conferencista.models[0].attributes.descriptionios.replace(/\n/g, "<br>");
      }
      var image = "";
      if(conferencista.models[0].attributes.image){
        image = conferencista.models[0].attributes.image._url;
      }
			var conferencistaText = '<img class="conferencista-img" src="'+image+'"><p class="name">'+conferencista.models[0].attributes.name+'</p><p>'+conferencista.models[0].attributes.description+'</p>';
            this.$el.html(handlebarsTemplate());
            this.translate();
			this.$el.find('#conferencista').html(conferencistaText);
        });
	},

	home: function() {
        EventHub.trigger('navigate', 'login', {
            trigger: true
        });
    },

	back: function(){
		 history.back();
	}
});
export default InformacionsubconferencistaView;
