import Backbone from 'backbone';
import ParseService from '~/services/common/parseService';
import Model from '~/models/parseModel';
import Collection from '~/collections/parseCollection';
/**
 * @member [reportSubCategoryService] reportSubCategoryService
 */
var reportSubCategoryService;

/**
 * Object reportSubCategory service, manages reports in SubCategories
 * @module services/reportSubCategoryService
 * @returns {reportSubCategoryService}
 */
export default function() { // lazy initialization. we export function which returns servies. this way service will be created only when it will be really necessary
    /**
     * Assigns ReportSubCategoryModel class with properties
     * @member [ReportSubCategory] ReportSubCategoryModel
     */
    var ReportSubCategoryModel = Model.extend({
        className: "subCategory",
        defaults: {
            'manualTitle': false,
        },
        /**
         * returns this reportSubCategoryService object parents categories as properties
         * @function fetchByCategory
         * @returns {fetch}
         */
        fetchByCategory: function() {
            return this.fetch({
                all: true,
                cache: false,
                rules: {
                    equals: [{
                        key: 'parentCategory',
                        val: { __type: "Pointer", className: "ReportCategory", objectId: "SlXx998MNf" }
                    }]
                }
            });
        }
    });
    /**
     * Represents a collection of ReportSubCategoryModel
     * @member [Pictures collection] ReportSubCategoryCollection
     */
    var ReportSubCategoryCollection = Collection.extend({
        model: ReportSubCategoryModel
    });
    /**
     * it's ReportSubCategory service, object type service of the collection
     * @member [reportSubCategory Service] ReportSubCategoryService
     */
    var ReportSubCategoryService = ParseService.extend({
        sortBy: 'subCatId',
        sortBy: 'priority',
        model: ReportSubCategoryModel,
        collection: ReportSubCategoryCollection,
        // you can also extend any method from ParseService here
    });
    return reportSubCategoryService || (reportSubCategoryService = new ReportSubCategoryService());
};
