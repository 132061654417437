// all application settings
var Settings = {
    parseAppId: 'devapp',
    parseKey: '',
    //parseUrl: 'http://localhost:1337/parse',
    parseUrl: 'https://devappshogun.herokuapp.com/parse',
    deepLinkUrl: '',
    privacityPoliticsURL: 'https://www.shogunmonitor.com/documents/POLITICAS%20DE%20PRIVACIDAD%20SHOGUN%20TECNOLOGIES%20S.A%20%202018.pdf',
    blockscreenshot: false,
    login: false

};

export const LanguageDefault = { "es" :
  {
     "AcceptBtn":"Aceptar",
     "AccepttermsSubtittle":"Para continuar debe aceptar los términos y condiciones de uso.",
     "AccepttermsSubtittle2":"Siga este enlace para saber más:",
     "AttachBtn":"ADJUNTAR ARCHIVO, IMAGEN o VIDEO",
     "Attachfilesreport":"Archivos adjuntos:",
     "BackBtn":"Regresar",
     "Btn_back":"Regresar",
     "Btn_home":"Inicio",
     "Btn_NewsBack":"Regresar",
     "CheckboxText":"He leído y aceptado los términos y condiciones de uso",
     "Comment_send":"ENVIAR",
     "Conferencistas_conferencistas":"Conferencistas",
     "ConsultBtn":"Consultar",
     "CopyBtn":"Copiar",
     "CopiedBtn":"Copiado",
     "CreateCommentPlaceHolder":"Realice un comentario",
     "CreateCommentThks":"Gracias",
     "CreateCommentThksMessage":"Su comentario es valioso para mejorar nuestros procedimientos y nuestra comunidad",
     "CreateCommentTittle":"Comentar denuncia",
     "CreateReport":"Creando Denuncia",
     "DescPlaceHolder":"¿Qué desea denunciar?",
     "Descriptionmsg":"Favor Ingrese la descripción",
     "Filetolarge":"El archivo es muy grande para subir al sistema, favor subir un archivo menor a 80mb",
     "Home_Conferencistas":"Conferencistas",
     "Home_News":"Noticias",
     "Home_Patrocinadores":"Patrocinadores",
     "Home_Programa":"Programa",
     "InfoText":"Trabajamos en seguridad, ciberseguridad, inteligencia, área forense, legal, materias del mar, psicología y formación.",
     "InfoText2":"Utilice la herramienta del buscador para encontrar una categoría",
     "InfoText3":"Seleccione una categoría",
     "InsertDetailsReportTittle": "Crear Denuncia de:",
     "inputsearchcode":"Buscar...",
     "LocalizationModalTittle":"Seleccione un lenguaje",
     "MainTittle":"Línea de Transparencia",
     "MakeQuestionClapfo":"REALIZAR Denuncia",
     "Modal2btn":"Regresar",
     "MyReportsBtn":"Mis denuncias",
     "MyReportsModalInfo":"Estas son las denuncias que ha realizado, seleccione para consultar.",
     "MyReportsModalTittle":"Mis Denuncias",
     "NewReportBtn":"Realizar denuncia",
     "NewReportForCat":"Realizar una denuncia sobre",
     "News_news":"Noticias",
     "NoReportFound":"No se encontro ningun reporte, verifique el código consultado",
     "OptionalText":"* No rellenar si desea preservar el anonimato",
     "Placeholder-home":"Consulta por código",
     "ReportActivity":"",
     "ReportCommentAdmin":"ADMINISTRADORES",
     "ReportCommentAnonymous":"Usuario Anónimo",
     "ReportCommentsTittle":"Comentarios",
     "ReportDays1":"Creado hace",
     "ReportDays2":"días",
     "ReportDescription":"Seleccione una actividad y realice una denuncia",
     "ReportDetailSubTittle":"Descripción del reporte o denuncia",
     "ReportDetailTittle":"Consulta denuncia",
     "ReportNewComment":"Crear comentario",
     "ReportStatus1":"En proceso...",
     "ReportStatus2":"Cerrado",
     "ReportStatus3":"Descartado",
     "search":"Buscar categoría",
     "SelectCategory":"Seleccione una categoria",
     "Selectprogram":"Ayuda a mejorar tu empresa notificando sobre problemas que hay en esta",
     "SendBtn":"Enviar Denuncia",
     "Singlenews_singlenews":"Noticia",
     "SubsidiaryBtn":"Entra a la filial electrónica",
     "Termnstittle":"Términos y Condiciones de uso",
     "ThksInfo":"Se ha generado una nueva denuncia. Si desea hacer seguimiento de su denuncia, guarde el siguiente código: ",
     "ThksTittle":"Gracias por su denuncia",
     "UserNameReportHolder":"Nombre del Denunciante",
     "WarningTittle":"Aviso"
  }

}

export default Settings;
