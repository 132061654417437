import Backbone from 'backbone';

import ParseService from '~/services/common/parseService';
import Model from '~/models/parseModel';
import Collection from '~/collections/parseCollection';

var reportSuperCategoryService;
export default function() { // lazy initialization. we export function which returns servies. this way service will be created only when it will be really necessary

    // model
    var ReportSuperCategoryModel = Model.extend({
        className: "superCategory",
        defaults: {
            'manualTitle': false,
        },
        checkIconType: function() {
            // this function returns the icon type.
            //return: 'SVG' or 'ion'
            var thisReportType = '';
            var reportIconRaw = this.attributes.icon;
            if (reportIconRaw.substring(2, 5) == 'xml') {
                // SVG icon
                thisReportType = 'SVG';
            } else if (reportIconRaw.substring(0, 3) == 'ion') {
                // ion icon.
                thisReportType = 'ion';
            }
            this._type = thisReportType;
            return thisReportType;
        } // end checkIconType
    });

    // collection
    var ReportSuperCategoryCollection = Collection.extend({
        model: ReportSuperCategoryModel
    });

    // service (extends ParseService)
    var ReportSuperCategoryService = ParseService.extend({
        sortBy: 'priority',
        model: ReportSuperCategoryModel,
        collection: ReportSuperCategoryCollection,
        // you can also extend any method from ParseService here
    });

    return reportSuperCategoryService || (reportSuperCategoryService = new ReportSuperCategoryService());
};
// only created once for application
