import Backbone from 'backbone';

import ParseService from '~/services/common/parseService';
import Model from '~/models/parseModel';
import Collection from '~/collections/parseCollection';

var picturesService;
export default function() {

    // model
    var PicturesModel = Model.extend({
        className: "Pictures",
        defaults: {
            'manualTitle': false,
        }
    });

    // collection
    var PicturesCollection = Collection.extend({
        model: PicturesModel
    });

    // service (extends ParseService)
    var PicturesService = ParseService.extend({
        sortBy: 'createdAt',
        model: PicturesModel,
        collection: PicturesCollection,
        createPicture: function(picture, success){
          var parsePicture = new Parse.File(Date.now().toString()+picture.name, picture);
          var model = new this.model("Pictures");
          model.set('user',Parse.User.current());
          model.set('picture', parsePicture)//model
          return model.save(null,{success: success});
        },
        createSimplePicture: function(picture, success){
          var model = new this.model("Pictures");
          model.set('user',Parse.User.current());
          model.set('picture', picture)//model
          return model.save(null,{success: success});
        },
        // you can also extend any method from ParseService here
    });

    return picturesService || (picturesService = new PicturesService());
};
// only created once for application
