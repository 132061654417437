function ImageResizeService() { }

ImageResizeService.prototype = {
	
	imageExtensions: ['.png','.tif','.tiff','.jpg','.jpeg','.jpe','.jfif','.bmp','.dib'],
	
	initialize: function() {},
	
	checkImage: function(ext){
		var result = false;
		for(var i = 0; !result && i < this.imageExtensions.length; ++i){
			result = this.imageExtensions[i] == ext;
		}
		return result;
	},
	
	resizeImage: function(src, returnFunc){
		var canvas = document.getElementById("draw_resize");
		var img = new Image();
		var that = this;
		img.onload = function() {
			var width = this.width;
			var height = this.height;
			//begin resize
			var temp = width - height;
			if(temp > 0){
				//width > height
				if(width > 1500){
					//resize
					var newWidth = 1500;
					var newHeight = newWidth * height / width;
					width = newWidth;
					height = newHeight;
				}
			}
			else{
				//width <= height
				if(height > 1500){
					//resize
					var newHeight = 1500;
					var newWidth = newHeight * width / height;
					width = newWidth;
					height = newHeight;
				}
			}
			//end resize
			canvas.width = width;
			canvas.height = height;
			var ctx = canvas.getContext("2d");
			ctx.drawImage(this, 0, 0, width, height);
			var resizeData = canvas.toDataURL();
			returnFunc(resizeData);
		}
		img.src = src;
	}
}
export default new ImageResizeService();