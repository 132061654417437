import Backbone from 'backbone';
import Handlebars, { log } from 'handlebars';
import Settings from '~/settings.js';
import EventHub from '~/eventHub';
import insertDetailscustomreport_template from '~/templates/report/insertDetailscustomreport.handlebars';
import customformtext_template from '~/templates/customformsfields/customformtext.handlebars';
import customformsmalltext_template from '~/templates/customformsfields/customformsmalltext.handlebars';
import customformradio_template from '~/templates/customformsfields/customformradio.handlebars';
import customformselect_template from '~/templates/customformsfields/customformselect.handlebars';
import customformfile_template from '~/templates/customformsfields/customformfile.handlebars';
import getReportService from '~/services/report/reportService.js';
import getReportSuperCategoryService from '~/services/report/reportSuperCategoryService';
import getReportSubCategoryService from '~/services/report/reportSubCategoryService.js';
import getFileService from '~/services/common/fileService';
import getUtilsService from '~/services/common/utilsService';
import imageResize from '~/services/common/imageResizeService';

var insertDetailscustomreport = Backbone.View.extend({
	className: 'insert-customreport-details',
	data: {},
	cont: null,
	file: null,
	fileunder80mb: [],
	customfiles: [],
	catimg:'',
	initialize: function() {
		$('.region-content-row').css('background','url("images/new/fondo.jpg") no-repeat center');
		$('.region-content-row').css('background-size','cover');
		$.get("images/new/fondo-insertreportdetails.jpg").done(function () {
			$('.region-content-row').css('background','url("images/new/fondo-insertreportdetails.jpg") no-repeat center');
			$('.region-content-row').css('background-size','cover');
		});
	},
	events: {
		'change .customupload': 'uploadfile',
		'change .accepttermsandconscustom': 'checktermsandcons',
		'click .sendcustomform' : 'needInformation',
		'click #btncancelcustomform': 'back',
		'click .discardreport3' : 'discarcustomfile',
		'click .openmodaltermsandcons' : 'openmodaltermsandcons',
		'click #modaltermsandconsform-cancel' : 'closemodaltermsandcons',
		'click .closemodalicon' : 'closemodaltermsandcons',
	},

	render: function() {
		this.attachEvents();
		return this;
	},

	back: function() {
		EventHub.trigger('navigate', 'selectcategory?supercat=' + this.data.supercategory + '&subcat=' + this.data.subcategory, {
			trigger: true
		});
	},

	openmodaltermsandcons: function(){
		const temrandcns = Parse.Config.current().get("termsandcons");
		$(".termsandconstext").html(temrandcns.esp)
		$("#customformtermsandcons").fadeIn();
	},

	closemodaltermsandcons: function() {
		$("#customformtermsandcons").fadeOut();
	},

	requiredfield: function(){
		var that = this;
		that.$el.find('.checkrequired').each(function(index, element){
			if ($(this).attr("key") == "true") {
				var fieldtittle = $(this).find('h1').text() + " *";
				$(this).find('h1').text(fieldtittle)
			}
		});
	},

	needInformation:function(){
		var createreport = true;
		var that = this;
		that.$el.find('.checkrequired').each(function(index, element){
			if ($(this).attr("key") == "true") {
				var currentcustomformitem = $(this).find('.customformitem').val();
				var fieldtittle = $(this).find('h1').text();
				var fieldtittle = fieldtittle.slice(0,fieldtittle.indexOf('*'));
				fieldtittle = fieldtittle.trim();
				fieldtittle = fieldtittle + " * <br/><br/> <span style='color: red; text-shadow: none;'>Este campo es obligatorio *";
				var radio = true;
				var select = true;
				if ($(this).attr("id") == "containercustomformradio") {
					radio = $(this).find('.customformitem').is(":checked");
				}
				if ($(this).attr("id") == "containercustomformselect") {
					if (currentcustomformitem == "Selecciona una opción") {
						currentcustomformitem = false;
					}
				}
				if (!currentcustomformitem || !radio ) {
					$(this).find('h1').html(fieldtittle);
					createreport = false;
				}
			}
		});
		if (!this.$el.find(".accepttermsandconscustom").is(":checked")) {
			createreport = false;
			$(".report-termsandcons").css({"border-color": "#FF0000", "border-width":"1px", "border-style":"solid","border-radius":"5px","padding":"5px"});
		}
		if(createreport){
				this.savecustomdata();
		}else{
				$(".needallinfo").show();
		}
	},

	checktermsandcons: function(){
		if (this.$el.find(".accepttermsandconscustom").is(":checked")) {
			$(".report-termsandcons").css({"border": "1px solid var(--send-button)", "padding":"5px","border-radius":"5px"});
		}else{
			$(".report-termsandcons").css({"border": "none"});
		}
	},

	uploadfile: function(e) {
		if (this.customfiles.length > 0){
			for (var i = 0; i < this.customfiles.length; i++) {
				if (this.customfiles[i].name == e.target.name) {
					this.customfiles.splice(i,1);
				}
			}
		}
		$("."+e.target.id).empty();
		for (var i = 0; i < e.target.files.length; i++) {
			if (e.target.files[i].size > 80000000) {
				if(arrlang[lang]){
					alert(arrlang[lang]['Filetolarge']);
					$('.customupload').html(arrlang[lang]['AttachBtn']);
				}
			}else{
				var namelenght = e.target.files[i].name.length;
				var extension = e.target.files[i].name.substring(namelenght-4, namelenght);
				if (extension.charAt(0) != '.'){
					var extension = e.target.files[i].name.substring(namelenght-5, namelenght);
				}
				var name= '';
				switch(extension) {
					  case ".png":
					    name = 'Imagen.png';
					    break;
					  case ".jpg":
					    name = 'Imagen.jpg'
					    break;
						case ".pdf":
						  name = 'Archivo.pdf'
						  break;
						case ".jpeg":
							name = 'Imagen.jpeg'
							break;
						case ".mp4":
							name = 'Video.mp4'
							break;
						case ".PNG":
							name = 'Imagen.png'
							break;
						case ".docx":
							name = 'Archivo.docx'
							break;
					  default:
					    name = 'Archivo'
					}
				$("."+e.target.id).append("<div class='singlecustomfile' id='"+e.target.files[i].name+"' key='"+e.target.id+"' >"+name+"<i class='fa fa-times-circle discardreport3'></i></div>");
			}
		}
		const arrayfiles = $("#"+e.target.id)[0].files;
		this.customfiles.push({name: e.target.name, fieldcustomfiles: arrayfiles });
	},

	discarcustomfile: function(e){
		var lista = [];
		var filename = e.target.closest('.singlecustomfile').id;
		var key = e.target.closest('.singlecustomfile').getAttribute("key");;
		e.target.closest('.singlecustomfile').remove();
		for (var i = 0; i < this.customfiles.length; i++) {
			var nametrime = this.customfiles[i].name.replace(/\s/g, '');
			nametrime = nametrime.slice(0, -1);
			if (nametrime == key) {
				for (var j = 0; j < this.customfiles[i].fieldcustomfiles.length; j++) {
					if (this.customfiles[i].fieldcustomfiles[j].name != filename) {
						lista.push(this.customfiles[i].fieldcustomfiles[j]);
					}
				}
				this.customfiles[i].fieldcustomfiles = lista;
			}
		}
	},

	async savecustomdata(){
		var that = this;
		if(arrlang[lang] && arrlang[lang]["CreateReport"] != undefined){
			window.spinnerDialog.show(arrlang[lang]["CreateReport"]);
		}else{
			window.spinnerDialog.show("Creando Reporte");
		}
		//empieza archivos
		var fileslength = 0;
		for (var i = 0; i < this.customfiles.length; i++) {
			fileslength = fileslength + this.customfiles[i].fieldcustomfiles.length;
		}
		var progressval = 1;
		var filesnames = [];
		window.spinnerDialog.show("Procesando archivo");
		getUtilsService.setUpFileLoader();
		for (var i = 0; i < this.customfiles.length; i++) {
			for (var j = 0; j < this.customfiles[i].fieldcustomfiles.length; j++) {
				getUtilsService.fileProgress(progressval/fileslength);
				progressval++;
				const filestring = await this.savefile(this.customfiles[i].fieldcustomfiles[j],this.customfiles[i].name);
				filesnames.push(filestring);
			}
		}
		getUtilsService.resetFileLoader();
		window.spinnerDialog.hide();
		//termina archivos
		var customdata = [];
		this.$el.find('.customformitem').each(function(index, element){
			var inputname = $(this).attr("name");
			var inputtype = $(this).attr("type");
			if(inputtype == 'radio' && $(this).is(":checked") ){
            	var inputval = $(this).val();
      		}
			if (inputtype == 'radio' && !$(this).is(":checked") ) {
				return true;
			}
			var inputval = $(this).val();
			if (inputtype == 'file') {
				var inputval = [];
				for (var i = 0; i < filesnames.length; i++) {
					filesnames[i]
					if (filesnames[i].name == inputname) {
						inputval.push(filesnames[i]);
					}
				}
			}
			var inputdata = {'name': inputname, 'type' : inputtype, 'val': inputval};
			customdata.push(inputdata);
		});
		this.data.customreportvalues = {customdata:customdata};
		getReportService().createReport(this.data).then(data => {
			if(!window.localStorage.codes ){
				window.localStorage.codes = "";
			}
			var date = new Date();
			var dateText = date.getDate()+ "/"+(date.getMonth()+1)+"/"+ date.getFullYear();
			window.localStorage.codes += data.id+"&"+that.data.subcategoryModel.attributes.superCat.attributes.prefijo+"&"+dateText + "-";
			EventHub.trigger('navigate', 'confirmreport', {
				trigger: true
			});
			setTimeout(function(){
				$(".code").html(data.id);
			}, 20);
			getPushNotificationService().subscribe("RID_"+data.id);
		},
		error => {
			window.spinnerDialog.hide();
			alert("Error al crear el reporte, favor revisar su conexión de internet.");
		});
	},

	async savefile(savecustomdatafile, savecustomdatafilefield){
		return new Promise((resolve,reject) => {
				var file = savecustomdatafile;
				var error = 'error';
				var namelenght =file.name.length;
				var extension = file.name.substring(namelenght-4, namelenght);
				if (extension.charAt(0) != '.'){
					extension = file.name.substring(namelenght-5, namelenght);
				}
				var files = '';
				var name = 'File_'+extension;
				var reader = new FileReader();
				var checkResize = imageResize.checkImage(extension);
				if(checkResize){
					var returnFunc = function(dataFile){
						var b64Data = dataFile.split('base64,')[1];
						var parseFile = new Parse.File(name, { base64: b64Data }, 'image/png');
						getFileService().createSimplecustomFile(parseFile,savecustomdatafilefield, result=> {
							var file = {url: result.attributes.file._url, name: result.attributes.customformfield }
							resolve(file);
						},error => {
							resolve(error);
							alert("Error al subir archivo, si desea agregarlo posteriormente por favor realizarlo por medio de un comentario y con una buena conexión a internet");
						});
					};
					reader.onload = function (e) {
						imageResize.resizeImage(e.target.result, returnFunc);
					};
					reader.readAsDataURL(file);
				}
				else{
					reader.onload = function (e) {
						switch(extension){
							case '.pdf':
								var parseFile = new Parse.File(name, Array.prototype.slice.call(new Uint8Array( e.target.result)), 'application/pdf');
							break;
							default:
								var parseFile = new Parse.File(name, Array.prototype.slice.call(new Uint8Array( e.target.result)));
							break;
						}
						getFileService().createSimplecustomFile(parseFile,savecustomdatafilefield,result=> {
							var file = {url: result.attributes.file._url, name: result.attributes.customformfield }
							resolve(file);
						},error => {
							resolve(error);
							alert("Error al subir archivo, si desea agregarlo posteriormente por favor realizarlo por medio de un comentario y con una buena conexión a internet.");
						});
					};
					reader.readAsArrayBuffer(file);
				}
  	});
	},

	translate: function(e){
		var that = this;
		var callback = data => {
			if(arrlang[lang] === undefined){ return }

			that.$el.find('.item').each(function(index, element){
				$(this).html(arrlang[lang][$(this).attr("key")]);
			});
			$('#textareaa').attr("placeholder", arrlang[lang][$('#textareaa').attr("key")]).val();
			$('#textareaaa').attr("placeholder", arrlang[lang][$('#textareaaa').attr("key")]).val();

			that.$el.find('.super-cat-title').each(function(index, element){
				if($(this).text().length > 0){
					var entry = arrlang[lang]["Supercategories"][$(this).attr("superCat")];
					if(entry === undefined){ return }
					$(this).find('div').text(entry["Name"]);
				}
			});
			that.$el.find('.Report-category').each(function(index, element){
				var entry = arrlang[lang]["Supercategories"][$(this).attr("superCat")];
				if(entry === undefined || entry[$(this).attr("id")] === undefined){ return }
				$(this).text(entry[$(this).attr("id")]);
			});
		}
		if(Object.keys(window.arrlang).length !== 0){
			callback();
		}
		this.listenTo(EventHub, 'translation', callback);
	},

	attachEvents: function() {
    var that = this;
    this.listenTo(EventHub, 'customreport:details', datacateg => {
		this.data = datacateg;
		var superCategoryPromise = getReportSuperCategoryService().fetch({
			all: true,
			cache: false,
			rules: {equals: [{key: 'objectId',val: datacateg.supercategory}]}
		}).then(supercategory => {
			var subCategoryPromise = getReportSubCategoryService().fetch({
				all: true,
				cache: false,
				rules: {equals: [{key: 'objectId',val: datacateg.subcategory}]}
			}).then(subcategory => {
				that.data.subcategoryModel = subcategory.models[0].parseObject;
				Parse.Cloud.run("GetCustomForm", {customformid: datacateg.customform}).then((customform) => {
					var customformfields;
					if (customform == undefined) {
						const defaultform = Parse.Config.current().get("defaultform");
						customformfields = defaultform.form;
					}else{
						customformfields = customform.attributes.fields;
					}
					var customformhandlebarsTemplate = Handlebars.template(insertDetailscustomreport_template);
					this.$el.html(customformhandlebarsTemplate({policy:Settings.privacityPoliticsURL, supercategoryname:supercategory.models[0].attributes.name, subcategoryname:subcategory.models[0].attributes.name}));
					for (var i in customformfields) {
						var params = {name:customformfields[i].localization[lang],options:customformfields[i].properties};
						var handlebarsTemplate = '';
						switch (customformfields[i].type) {
							case 'text':
								params.placeholder = customformfields[i].properties.placeholder[lang];
								handlebarsTemplate = Handlebars.template(customformtext_template);
							break;
							case 'smalltext':
								params.placeholder = customformfields[i].properties.placeholder[lang];
								handlebarsTemplate = Handlebars.template(customformsmalltext_template);
							break;
							case 'radio':
								for (let index = 0; index < params.options.options.length; index++) {
									params.options.options[index].value = params.options.options[index].value[lang];
								}
								handlebarsTemplate = Handlebars.template(customformradio_template);
							break;
							case 'select':
								for (let index = 0; index < params.options.options.length; index++) {
									params.options.options[index].value = params.options.options[index].value[lang];
								}
								handlebarsTemplate = Handlebars.template(customformselect_template);
							break;
							case 'file':
								handlebarsTemplate = Handlebars.template(customformfile_template);
								var nametrime = params.name.replace(/\s/g, '');
								nametrime = nametrime.slice(0, -1);
								params.nametrim = nametrime;
							break;
							default:
						}
						$("#customformfields").append(handlebarsTemplate(params));
					}
					if(supercategory.models[0].attributes.iconFile._url != undefined){
						$('#topimginsertrepordet').attr("src", supercategory.models[0].attributes.iconFile._url);
					}else{
						$('#topimginsertrepordet').attr("src", "images/new/icons/7sO96C21Pf.svg");
					}
					this.requiredfield();
					this.translate();
					window.spinnerDialog.hide();
				});
	      });
	    });
		});
	},

	back: function() {
		EventHub.trigger('navigate', 'selectcategory?supercat=' + this.data.supercategory + '&subcat=' + this.data.subcategory, {
			trigger: true
		});
	},

});
export default insertDetailscustomreport;
