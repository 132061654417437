import Backbone from 'backbone';
import EventHub from '~/eventHub';
import getUtilsService from '~/services/common/utilsService';
import Settings from '~/settings.js';
import ParseService from '~/services/common/parseService';
import Model from '~/models/parseModel';
import Collection from '~/collections/parseCollection';


var pushNotificationService;
export default function() { // lazy initialization. we export function which returns servies. this way service will be created only when it will be really necessary

    // model
    var PushNotificationModel = Model.extend({
        className: "_Installation",
        defaults: {
            'manualTitle': false,
        },
    });

    // collection
    var PushNotificationCollection = Collection.extend({
        model: PushNotificationModel,
        getByHtmlId: function(HTMLId) {
            return this.where({ 'htmlID': HTMLId })[0];
        },
    });

    // service (extends ParseService)
    var PushNotificationService = ParseService.extend({
        sortBy: 'createdAt',
        itemsOnPage: 100, // load by N items, this overrides the 1k limit 'parseService.js'
        model: PushNotificationModel,
        collection: PushNotificationCollection,
        initNotifications: function(){
          var that = this;

          FirebasePlugin.grantPermission(function(hasPermission){
              
          });

          FirebasePlugin.onMessageReceived(function(message) {
      				if(message.kind == "report"){
                if(message.tap == 'background'){
          				var id = message.id;
          				if(message.id != undefined){
          					EventHub.trigger('navigate', 'report/' + id, {
          						trigger: true
          					});
          				}
          			}else{
                  $('#push-message').show();
        					$('#push-message .title').html("<span class='item'>Nuevo <span>"+message.title);
                  $('#push-message .title span').attr("key","PushRepTitle");
        					$('#push-message .body').html(""+message.body+"<br><a href='#report/"+message.id+"' class='item' key='PushSeeReport'>ver reporte</a>");
                }
      				}

              if(message.kind == "news"){
        				localStorage.newsTotal += 1;
        				var info = '';
        				var click = '';
        				if(message.body.length > 200){
        					info += message.body.substr(0,199);
        					info += "<br><a class='push-extendtext item' href='#singlenews/"+message.idNews+"' key='PushSeeMore'>Ver más</a>";
        				}
        				else{
        					info += message.body;
        				}
      					if(message.url != ''){
    				      click = 'cordova.InAppBrowser.open("'+message.url+'", "_system", "location=yes");';
    			        info +="<br><br><div class='push-link item' onclick='"+click+"' key='CheckAttachedFile'>Ver enlace adjunto</div>";
      					}
        			  if(message.imageFile != undefined){
        					info +="<br><br><img src='"+message.imageFile+"'>";
        				}
        				$('#push-message .body').html(info);
                $('#push-message .title span').attr("key","PushNews");
        				$('#push-message').show();
        			}
              that.translate();

          }, function(error) {
            console.log("push Error");
              console.error(error);
          });

          FirebasePlugin.subscribe("all", function(){
              
          }, function(error){
               console.error("Error subscribing to topic: " + error);
          });

        },
        subscribe:function(channel){
          var that = this;
          FirebasePlugin.subscribe(channel, function(){
              
          }, function(error){
               console.error("Error subscribing to topic: " + error);
          });
        },
        unsubscribe:function(channel) {
          var that = this;
          FirebasePlugin.unsubscribe(channel, function(){
              
          }, function(error){
               console.error("Error unsubscribing from topic: " + error);
          });
        },
        removeAllChannels:function(){
          var that = this;
          Parse.Session.current().then(function(session) {
            that.saveChannels([],session);
            getUtilsService.setCookie("channels","",100000);
          });
        },
        saveChannels:function(channels,session){
          var params = {_ApplicationId:Settings.parseAppId,
              _ClientVersion:"js1.9.2",
              _InstallationId:session.get('installationId'),
              _JavaScriptKey:Settings.parseKey,
              _SessionToken:Parse.User.current().attributes.sessionToken,
              _method:"PUT",
              channels:channels};
          $.ajax({
            url: Settings.parseUrl +"/classes/_Installation/"+getUtilsService.getCookie("register"),
            type:"POST",
            data:JSON.stringify(params),
            contentType:"application/json; charset=utf-8",
            dataType:"json",
            success: function(){}
          });
        },
        translate: function(){
          var that = this;
          var callback = data => {
            if(arrlang[lang] === undefined){ return }
            $('#push-message').find('.item').each(function(index, element){
              $(this).text(arrlang[lang][$(this).attr("key")]);
            });
          }

          if(Object.keys(window.arrlang).length !== 0){
            callback();
          }
        }
    });

    return pushNotificationService || (pushNotificationService = new PushNotificationService());
};
