// Dependencies
import Backbone from 'backbone';
import Handlebars from 'handlebars';
import EventHub from '~/eventHub';

// Services
import getNewsService from '~/services/report/newsService';

// Handlebars
import template from '~/templates/report/news.handlebars';

var NewsView = Backbone.View.extend({
	className: 'newsView',
	initialize: function() {
		$('.region-content-row').css('background','url("images/new/fondo.jpg") no-repeat center');
		$('.region-content-row').css('background-size','cover');
		$.get("images/new/fondo-news.jpg").done(function () {
			$('.region-content-row').css('background','url("images/new/fondo-news.jpg") no-repeat center');
			$('.region-content-row').css('background-size','cover');
		});
	},
	events: {
		'click .btnback': 'back',
		'click .singlenews': 'gotoSingleNews'
	},

	translate: function(e){
		var that = this;
		var callback = data => {
			if(arrlang[lang] === undefined){ return }
			that.$el.find('.item').each(function(index, element){
				$(this).text(arrlang[lang][$(this).attr("key")]);
			});
		}

		if(Object.keys(window.arrlang).length !== 0){
			callback();
		}
		this.listenTo(EventHub, 'translation', callback);
	},

	render: function() {
		var handlebarsTemplate = Handlebars.template(template);
		this.$el.html(handlebarsTemplate());
		this.renderNews();
		this.translate();
		return this;
	},

	renderNews: function() {
		var newsPromise = getNewsService().fetch({
			all: true,
			cache: true
		}).then(news => {
			var handlebarsTemplate = Handlebars.template(template);
			var listText = "";
			for(var i = 0; i < news.models.length; ++i){
				var date = new Date(news.models[i].attributes.createdAt);
				var options = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true};
				var localDate = date.toLocaleString('es', options);
				var display = 'display:none;';
				if(localStorage.newsCodes){
					if(localStorage.newsCodes.indexOf(news.models[i].attributes.id) == -1){
						display = '';
						var newsCodes = localStorage.newsCodes.split("-");
					}
				}
				else{
					display = '';
				}
				listText += '<div class="singlenews" id="'+news.models[i].attributes.id+'"><div class="info"><p class="name"><i id="alert_news'+news.models[i].attributes.id+'" style="'+display+'" class="alert_news fa-exclamation-circle fa fa-lg"> </i>'+news.models[i].attributes.title+'</p><p class="date">'+localDate+'</p></div></div>';
			}
			if(localStorage.newsCodes){
				var newsCodes = localStorage.newsCodes.split("-");
				var checkCode = false;
				newsCodes.pop();
				for(var i = 0; i < newsCodes.length; ++i){
					for(var j = 0; j < news.models.length && !checkCode; ++j){
						checkCode = newsCodes[i] == news.models[j].attributes.id;
					}
					if(!checkCode){
						newsCodes.splice(i, 1);
					}
					checkCode = false;
				}
				localStorage.newsCodes = '';
				if(newsCodes.length > 0){
					for(var i = 0; i < newsCodes.length; ++i){
						localStorage.newsCodes += ''+newsCodes[i]+'-';
					}
				}
			}
			localStorage.newsTotal = news.models.length;
			var dateAlert = new Date();
			window.localStorage.newsAlertDate = dateAlert;
			window.spinnerDialog.hide();
			this.$el.html(handlebarsTemplate());
			this.translate();
			this.$el.find('#list').html(listText);
		});
	},

	back: function() {
		EventHub.trigger('navigate', 'login', {
			trigger: true
		});
	},

	gotoSingleNews: function(e){
		var id = $(e.currentTarget).attr("id");
		if(id){
			EventHub.trigger('navigate', 'singlenews', { trigger: true });
			EventHub.trigger('singlenews:setState', { singlenewsID: id });
		}
	}
});
export default NewsView;
