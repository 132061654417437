// Dependencies
import Backbone from 'backbone';
import Handlebars from 'handlebars';
import EventHub from '~/eventHub';
// Handlebars
import template from '~/templates/common/sponsors.handlebars';

var SponsorsView = Backbone.View.extend({
    className: 'SponsorsView',

    initialize: function() {
      $('.region-content-row').css('background','url("images/new/fondo.jpg") no-repeat center');
      $('.region-content-row').css('background-size','cover');
      $.get("images/new/fondo-sponsors.jpg").done(function () {
  			$('.region-content-row').css('background','url("images/new/fondo-sponsors.jpg") no-repeat center');
  			$('.region-content-row').css('background-size','cover');
  		});
    },

    events: {
        'click .btnhome': 'home'
    },

     translate: function(e){
      var that = this;
      var callback = data => {
        if(arrlang[lang] === undefined){ return }
        that.$el.find('.item').each(function(index, element){
          $(this).text(arrlang[lang][$(this).attr("key")]);
        });
      }
      if(Object.keys(window.arrlang).length !== 0){
        callback();
      }
      this.listenTo(EventHub, 'translation', callback);
    },

    render: function() {
      var handlebarsTemplate = Handlebars.template(template);
      this.$el.html(handlebarsTemplate());
      this.translate();
      var that = this;
      this.listenTo(EventHub, 'sponsors:setAll', data => {
          var handlebarsTemplate = Handlebars.template(template);
          data.infoCollection.models[0].linkforbuttoninsponsors = arrlang[lang]['linkforbuttoninsponsors'];
          this.$el.html(handlebarsTemplate(data.infoCollection.models[0]));
          that.translate();
          window.spinnerDialog.hide();
      });
      return this;
    },

	home: function() {
        EventHub.trigger('navigate', 'login', {
            trigger: true
        });
    }
});
export default SponsorsView;
