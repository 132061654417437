// Depend#encies
import Backbone from 'backbone';
import Handlebars from 'handlebars';
import EventHub from '~/eventHub';
import Settings from '~/settings.js';
import getReportSuperCategoryService from '~/services/report/reportSuperCategoryService';
import template from '~/templates/common/search.handlebars';

var SearchView = Backbone.View.extend({
    supercategories: [],
    className: 'searchView',

    initialize: function() {
      $('.region-content-row').css('background','url("images/new/fondo.jpg") no-repeat center');
      $('.region-content-row').css('background-size','cover');
      $.get("images/new/fondo-search.jpg").done(function () {
  			$('.region-content-row').css('background','url("images/new/fondo-search.jpg") no-repeat center');
  			$('.region-content-row').css('background-size','cover');
  		});
    },

    events: {
        'click .btnhome': 'home',
        'click #searchbtn' : 'gotosubcat',
        'keyup #categoriename' : 'checkinput',
        'click #closegoback':'home',
        'click .listitems': 'gotosubcat',
        'click #modal-cancel' : 'closemodal',
    },

    closemodal: function(){
      $('#modaltermsandcon2').fadeOut();
    },

    checkinput: function(e){
      var arr = this.supercategories;
      var val = $("#categoriename").val();
      var arrcateg = [], arrcategmetad = [], arrcategincludeinname = [],arrmetadataincludeinname = [] , arrshow = [];
      var iconfile = '';
      $('#list-items').empty();
      for (var i = 0; i < arr.length; i++) {
        var val2 = val.toUpperCase();
        if( arr[i].attributes.iconFile != undefined){
          iconfile = arr[i].attributes.iconFile._url;
        }else{
          iconfile = "images/new/icons/7sO96C21Pf.svg";
        }
        if (arr[i].attributes.name.substr(0, val.length).toUpperCase() == val.toUpperCase()) {
          arrcateg.push('<div class="listitems" id="'+arr[i].id+'">'+"<p>" + arr[i].attributes.name+'</p><div class="categimg"><img class="imgs" src="'+iconfile +'"></div></div>');
        }
        if (arr[i].attributes.metadatos != undefined) {
          for (var ii = 0; ii < arr[i].attributes.metadatos.length; ii++) {
            if (arr[i].attributes.metadatos[ii].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
              arrcategmetad.push('<div class="listitems" id="'+arr[i].id+'">'+"<p>" + arr[i].attributes.name+'</p><div class="categimg"><img class="imgs" src="'+iconfile +'"></div></div>');
            }
            var metadataname = arr[i].attributes.metadatos[ii].toUpperCase();
            var searchinmetadata = metadataname.search(val2);
            if (metadataname.substr(searchinmetadata, val2.length).toUpperCase() == val2) {
              arrmetadataincludeinname.push('<div class="listitems" id="'+arr[i].id+'">'+"<p>" + arr[i].attributes.name+'</p><div class="categimg"><img class="imgs" src="'+iconfile +'"></div></div>');
            }
          }
        }
        var categoriename = arr[i].attributes.name.toUpperCase();
        var searchinname = categoriename.search(val2);
        if (categoriename.substr(searchinname, val2.length).toUpperCase() == val2) {
          arrcategincludeinname.push('<div class="listitems" id="'+arr[i].id+'">'+"<p>" + arr[i].attributes.name+'</p><div class="categimg"><img class="imgs" src="'+iconfile +'"></div></div>');
        }
      }
      //agregar matriz de levenstein
      var resLevens = this.levensteinRes(val2);
      arrshow = arrcateg.concat(arrcategmetad,arrcategincludeinname,arrmetadataincludeinname);
      function removeduplicates(data){
        return [...new Set(data)]
      }
      arrshow = removeduplicates(arrshow);
      if(arrshow.length < 5){
        var arrlevens = [];
        for(var i = 0; i < resLevens.length; i++){
          if(arr[resLevens[i].i].attributes.iconFile != undefined){
            iconfile = arr[resLevens[i].i].attributes.iconFile._url;
          }
          else{
            iconfile = "images/new/icons/7sO96C21Pf.svg";
          }
          arrlevens.push('<div class="listitems" id="'+arr[resLevens[i].i].id+'">'+"<p>" + arr[resLevens[i].i].attributes.name+'</p><div class="categimg"><img class="imgs" src="'+iconfile +'"></div></div>');
        }
        arrshow = arrshow.concat(arrshow, arrlevens);
        arrshow = removeduplicates(arrshow);
        arrshow = arrshow.slice(0, 5);
      }
      for (var i = 0; i < arrshow.length; i++) {
        $('#list-items').append(arrshow[i]);
      }
      if (val.length == 0) {
        $('#list-items').empty();
      }
    },

    levensteinRes: function(val){
      var arr = this.supercategories;
      var res = [];
      var resLevens = [];
      for (var i = 0; i < arr.length; i++) {
        resLevens.push({
          i: i,
          levens: this.levensteinCalc(val, arr[i].attributes.name.slice(0, val.length))
        });
      }
      resLevens.sort( (obj1, obj2) => {
        if (obj1.levens < obj2.levens) return -1;
        if (obj1.levens > obj2.levens) return 1;
        return 0;
      });
      res = resLevens.slice(0, 10);
      return res;
    },

    levensteinCalc: function(wwordA, wordB){
      const m = wwordA.length;
      const n = wordB.length;
      // Creamos una matriz de distancias con dimensiones (m+1) x (n+1)
      const distances = [];
      for (let i = 0; i <= m; i++) {
        distances[i] = [];
        distances[i][0] = i;
      }
      for (let j = 1; j <= n; j++) {
        distances[0][j] = j;
      }
      // Calculamos las distancias
      for (let i = 1; i <= m; i++) {
        for (let j = 1; j <= n; j++) {
          const cost = (wwordA.charAt(i - 1) !== wordB.charAt(j - 1)) ? 1 : 0;
          distances[i][j] = Math.min(
            distances[i - 1][j] + 1,         // Eliminación
            distances[i][j - 1] + 1,         // Inserción
            distances[i - 1][j - 1] + cost   // Reemplazo
          );
        }
      }
      // Devolvemos la distancia mínima
      return distances[m][n];
    },

    gotosubcat: function(e){
      var search = {id:e.currentTarget.id,fromsearch:true};
      localStorage.searchx = true;
      var selectedcategorie = '';
      for (var i = 0; i < this.supercategories.length; i++) {
        if (this.supercategories[i].id == e.currentTarget.id){
          selectedcategorie = this.supercategories[i];
        }
      }
      if (selectedcategorie.attributes.appslinks != undefined) {
        if (selectedcategorie.attributes.appslinks.app == true) {
          $('#modalcontent').empty();
          $('.modaltermsandcon2img').attr("src", selectedcategorie.attributes.iconFile._url );
          var web = '<br><a href="'+selectedcategorie.attributes.appslinks.web+'" onclick="cordova.InAppBrowser.open('+"'"+selectedcategorie.attributes.appslinks.web+"'"+', '+"'_system'"+', '+"'location=yes'"+');" target="_blank" ><div id="linkweb" ></div></a>';
          var android = '<a href="'+selectedcategorie.attributes.appslinks.android+'" onclick="cordova.InAppBrowser.open('+"'"+selectedcategorie.attributes.appslinks.android+"'"+', '+"'_system'"+', '+"'location=yes'"+');" target="_blank" ><div id="linkandroid"></div></a>';
          var ios = '<a href="'+selectedcategorie.attributes.appslinks.ios+'" onclick="cordova.InAppBrowser.open('+"'"+selectedcategorie.attributes.appslinks.ios+"'"+', '+"'_system'"+', '+"'location=yes'"+');" target="_blank" ><div id="linkios"></div></a><br>';
          var subtext = '<div class="subtitle item" key="AccepttermsSubtittle" >o descargando la app de la organizaci&oacute;n</div><br>'
          var cancelbtn = '<div class="modal2aceptbtn cancel-button acceptterms" id="modal-cancel"><div class="item" key="Modal2btn">Atrás</div></div><br>';
          $('#modalcontent').append(web,subtext,android,ios,cancelbtn);
          $('#modaltermsandcon2').fadeIn();
        }else{
          Settings.login = true;
          EventHub.trigger('navigate', 'home', { trigger: true });
          EventHub.trigger('sendsearchcode', { searcharr: search });
        }
      }else{
        Settings.login = true;
        EventHub.trigger('navigate', 'home', { trigger: true });
        EventHub.trigger('sendsearchcode', { searcharr: search });
      }
    },

    getsupercategories: function(){
      this.supercategories = [];
      Parse.Cloud.run("getsupercategories").then((supercategs) => {
        this.supercategories = supercategs;
      });
    },

    translate: function(e){
      var that = this;
      var callback = data => {
        if(arrlang[lang] === undefined){ return }
        that.$el.find('#categoriename').attr('placeholder',arrlang[lang]["inputsearchcode"]);
        that.$el.find('.item').each(function(index, element){
          $(this).text(arrlang[lang][$(this).attr("key")]);
        });
      }
      if(Object.keys(window.arrlang).length !== 0){
        callback();
      }
      this.listenTo(EventHub, 'translation', callback);
    },

    render: function() {
      Parse.serverURL = Settings.parseUrl;
      Parse.initialize(Settings.parseAppId, Settings.parseKey);
      var handlebarsTemplate = Handlebars.template(template);
      this.$el.html(handlebarsTemplate());
      window.spinnerDialog.hide();
      this.getsupercategories();
      this.translate();
      return this;
    },

	  home: function() {
      EventHub.trigger('navigate', 'login', {
        trigger: true
      });
    }
});
export default SearchView;
