import Backbone from 'backbone';

import ParseService from '~/services/common/parseService';
import Model from '~/models/parseModel';
import Collection from '~/collections/parseCollection';

var informationService;
export default function() { // lazy initialization. we export function which returns servies. this way service will be created only when it will be really necessary

    // model
    var InformationModel = Model.extend({
        className: "Information",
        defaults: {
            'manualTitle': false,
        },

    });

    // collection
    var InformationCollection = Collection.extend({
        model: InformationModel
    });

    // service (extends ParseService)
    var ReportCategoryService = ParseService.extend({
        sortBy: 'createdAt',
        model: InformationModel,
        collection: InformationCollection,
        // you can also extend any method from ParseService here
    });

    return informationService || (informationService = new ReportCategoryService());
};
// only created once for application
