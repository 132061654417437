import Parse from 'parse';

/**
 * Rules for use with the cache. They represent a Parse Query without state.
 * @constructor
 */
function Rules(className) {
    this.equals= [];
    this.greaterThanOrEqualTo={};
    this.lessThanOrEqualTo={};
    this.includes= {};
    this.contains= {};
    this.matchesQuery= {};
    this.initialize(className);
}
Rules.extend = Backbone.Model.extend; // extend helper from Backbone


Rules.or = function(leftRule, rightRule){
    var className;
    if(leftRule.className){
        className = leftRule.className;
    } else if(rightRule.className){
        className = rightRule.className;
    }
    var ret = new Rules(className);
    ret.or = [leftRule, rightRule];
    return ret;
}

Rules.prototype = {
    initialize: function(className) {
        if(typeof className != 'undefined'){
            this.setClassName(className);
        }
    },

    include: function (property) {
        this.includes[property] = true;
    },
    addEqualsRule: function (key, val) {
        this.equals.push({
            'key': key,
            'val': val,
        });
    },
    addGreaterThanOrEqualTo: function (key, val) {
        this.greaterThanOrEqualTo[key] = val;
    },
    addLessThanOrEqualTo: function (key, val) {
        this.lessThanOrEqualTo[key] = val;
    },
    addContainsRule: function (key, val) {
        this.contains[key] = val;
    },
    /**
     * @param {string} key The key that contains a pointer that must match the rules
     * @param {Object} rules A Rules obj that the pointer must match against
     */
    addMatchesQuery: function(key, rules){
        this.matchesQuery[key] = rules;
    },
    setClassName: function(className){
        this.className = className;
    },
    /**
     * @param {string=} modelName - Name of the Parse Model to query, if not specified, checks the className attribute
     */
    toParseQuery: function(modelName){
        var query;
        if (this.or) {
            query = Parse.Query.or(this.or[0].toParseQuery(), this.or[1].toParseQuery());
        } else {
            if (typeof modelName == 'undefined') {
                modelName = this.className;
            }
            query = new Parse.Query(modelName);
        }
        for (var key in this.includes) {
            if (this.includes.hasOwnProperty(key)) {
                query.include(key);
            }
        }
        for (var i = 0; this.equals && i < this.equals.length; ++i) {
            query.equalTo(this.equals[i].key, this.equals[i].val);
        }
        for (var key in this.greaterThanOrEqualTo) {
            if (this.greaterThanOrEqualTo.hasOwnProperty(key)) {
                query.greaterThanOrEqualTo(key, this.greaterThanOrEqualTo[key]);
            }
        }
        for (var key in this.lessThanOrEqualTo) {
            if (this.lessThanOrEqualTo.hasOwnProperty(key)) {
                query.lessThanOrEqualTo(key, this.lessThanOrEqualTo[key]);
            }
        }
        for (var key in this.contains) {
            if (this.contains.hasOwnProperty(key)) {
                query.contains(key, this.contains[key]);
            }
        }
        for (var key in this.matchesQuery) {
            if (this.matchesQuery.hasOwnProperty(key)) {
                query.matchesQuery(key, this.matchesQuery[key].toParseQuery());
            }
        }
        return query;
    },
};

export default Rules;