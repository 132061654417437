export default {"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable, alias2=container.lambda, alias3=container.escapeExpression;

  return "      <div class=\"normal-category category\" id=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.id : stack1), depth0))
    + "\" superCat=\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.superCat : stack1)) != null ? stack1.id : stack1), depth0))
    + "\">\r\n      "
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.name : stack1), depth0))
    + "\r\n    </div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.propertyIsEnumerable, alias2=container.lambda, alias3=container.escapeExpression;

  return "      <div class=\"normal-subcategory subCategories\" id=\""
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.id : stack1), depth0))
    + "\" category=\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.parentCategory : stack1)) != null ? stack1.id : stack1), depth0))
    + "\" superCat=\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.superCat : stack1)) != null ? stack1.id : stack1), depth0))
    + "\" customform=\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.customform : stack1)) != null ? stack1.id : stack1), depth0))
    + "\">\r\n         <!--removeIf(capacitaOnline)-->\r\n        "
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.name : stack1), depth0))
    + "\r\n        <!--endRemoveIf(capacitaOnline)-->\r\n        \r\n      </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", alias5=container.escapeExpression;

  return "<div class=\"category-report\">\r\n  <div class=\"col-xs-12 col-sm-6 col-sm-offset-3\">\r\n    \r\n    <!--removeIf(showtopimgethicline)-->\r\n      <img src=\"images/new/shogun_SG.png\" class=\"header-logo\">\r\n    <!--endRemoveIf(showtopimgethicline)-->\r\n     \r\n    <div class=\"categoryText item\" key=\"NewReportForCat\">\r\n      Crear reporte de\r\n    </div>\r\n    \r\n    <br>\r\n     <!--removeIf(!customcategorydescription)-->\r\n    <div class=\"cat-description item\" key='ReportDescription2' superCat="
    + alias5(((helper = (helper = helpers.superCatId || (depth0 != null ? depth0.superCatId : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"superCatId","hash":{},"data":data,"loc":{"start":{"line":14,"column":72},"end":{"line":14,"column":86}}}) : helper)))
    + "> "
    + alias5(((helper = (helper = helpers.supercategorydes || (depth0 != null ? depth0.supercategorydes : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"supercategorydes","hash":{},"data":data,"loc":{"start":{"line":14,"column":88},"end":{"line":14,"column":108}}}) : helper)))
    + " </div>\r\n    <br>\r\n    <!--endRemoveIf(!customcategorydescription)-->\r\n    <div class=\"categories-container\">\r\n"
    + ((stack1 = helpers.each.call(alias2,(depth0 != null ? depth0.categories : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":4},"end":{"line":22,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = helpers.each.call(alias2,(depth0 != null ? depth0.subCategories : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":4},"end":{"line":30,"column":13}}})) != null ? stack1 : "")
    + "  </div>\r\n  <div class=\"cancel-button standar-button back\"><div class=\"item\" key=\"BackBtn\">REGRESAR</div></div>\r\n  <div class=\"col-md-12 row-2\">\r\n        <img class=\"footer-logo\" src=\"images/new/shogun_logo_blanco.png\">\r\n    </div>\r\n</div>\r\n";
},"useData":true};