export default {"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.propertyIsEnumerable, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function";

  return "<div class=\"programa col-sm-12 col-sx-12 \">\r\n		<div class=\"main-container\">\r\n			<img class=\"app-img\" src=\"images/new/shogun_logo_512x512.png\">\r\n			<div class=\"tittle-container\">\r\n				<img style=\"width: 100%;\" class=\"patrocinadores-img\" src=\""
    + alias2(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.Partners : stack1)) != null ? stack1._url : stack1), depth0))
    + "\"><br>\r\n				<br>\r\n				<!--removeIf(!showbuttonlinkinsponsors)-->\r\n					<a style=\"text-decoration: none;\" onclick=\"cordova.InAppBrowser.open('"
    + alias2(((helper = (helper = helpers.linkforbuttoninsponsors || (depth0 != null ? depth0.linkforbuttoninsponsors : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"linkforbuttoninsponsors","hash":{},"data":data,"loc":{"start":{"line":8,"column":75},"end":{"line":8,"column":102}}}) : helper)))
    + "', '_system', 'location=yes');\" href=\""
    + alias2(((helper = (helper = helpers.linkforbuttoninsponsors || (depth0 != null ? depth0.linkforbuttoninsponsors : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"linkforbuttoninsponsors","hash":{},"data":data,"loc":{"start":{"line":8,"column":140},"end":{"line":8,"column":167}}}) : helper)))
    + "\" target=\"_blank\"><div class=\"standar-button\"><div class=\"item\" key=\"Btn_linkinsponsors\">Ir al link</div></div></a>\r\n				<!--endRemoveIf(!showbuttonlinkinsponsors)-->\r\n				<div class=\"btnhome\"><div class=\"item\" key=\"Btn_home\">Inicio</div></div>\r\n				<br><br>\r\n			</div>\r\n		</div>\r\n</div>\r\n";
},"useData":true};