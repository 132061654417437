import Handlebars from 'handlebars';
import fileLoaderTemplate from '~/templates/common/fileLoader.handlebars';

function UtilsService() {}
UtilsService.prototype = {
    counter: 0,
    initialize: function() {

    },
    /**
     * sets date format to latn
     * @function fixDateFormat
     * @param date
     * @returns [date string format]
     * @memberOf UtilsService.prototype
     */
    fixDateFormat: function(date) {
        /**
         * @member [string] format
         */
        var format = '';
        /**
         * @member [year: string, month: string, day: string] options
         */
        var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        //set format
        if (date.toLocaleDateString) {
            format = date.toLocaleDateString("latn", options);
        } else {
            format = (new Date(date)).toLocaleDateString("latn", options);
        }
        return format;
    },

    fixDateFormat2: function(date) {
        /**
         * @member [string] format
         */
        var format = '';
        /**
         * @member [year: string, month: string, day: string] options
         */
        var options = {
                //weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            };
        //set format
        if (date.toLocaleDateString) {
            format = date.toLocaleDateString("es-GB", options);
        } else {
            format = (new Date(date)).toLocaleDateString("es-GB", options);
        }
        return format;
    },

    waitFor: function(object, param) {
        var result = new Promise(function(resolve, reject) {
            if (!(param in object)) {
                var check = function() {
                    if (!(param in object)) {
                        setTimeout(check, 10);
                    } else {
                        resolve("");
                    }
                };
                check();
            } else {
                resolve("");
            }
        });
        return result;
    },

    getSimpleDate: function(date) {
        var currentDate = new Date();
        var timeDiff = Math.abs(currentDate.getTime() - date.getTime());
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        return diffDays;
    },

    getPlatform: function() {
        var platformInfo = {};
        if (navigator.userAgent.match(/Android/i)) {
            platformInfo.android = true;
        } else {
            if (navigator.userAgent.match(/iPhone/i) ||
                navigator.userAgent.match(/iPad/i) ||
                navigator.userAgent.match(/iPod/i)||navigator.userAgent.match(/Mobile/i))  {
                platformInfo.ios = true;
            } else {
                platformInfo.browser = true;
            }
        }
        return platformInfo;
    },

    showSpinner: function(){
      if(window.SpinnerDialog){
        SpinnerDialog.show("","cargando");
      }
    },

    hideSpinner: function(){
      if(window.SpinnerDialog){
        SpinnerDialog.hide();
      }
    },

    fixArticleFormat: function(content){
      var result = content;
      result = result.replace('src="//www.youtube.com','src="https://www.youtube.com');
      return result;
    },

    setCookie: function(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },

    getCookie: function (cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },

    setUpFileLoader: function(){
      var handlebarsTemplate = Handlebars.template(fileLoaderTemplate);
      $('#loader .loading-gif').hide();
      $('#loader .file-loader').html(handlebarsTemplate());
      this.fileProgress(0);
    },

    resetFileLoader: function(){
      $('#loader .file-loader').empty();
      $('#loader .loading-gif').show();
    },
    
    fileProgress: function(value){
      var percent = Math.round(value * 100);
      $('#loader .file-loader .text').text(""+percent);
      $('#loader .file-loader .left-side').css("transform", `rotate(calc(${percent} * 3.6deg))`);

      if(percent <= 50){
        $('#loader .file-loader .right-side').hide();
      } else {
        $('#loader .file-loader .right-side').show();
        $('#loader .file-loader .pie').css("clip", "auto");
        $('#loader .file-loader .right-side').css("transform", "rotate(180deg)");
      }
    }
}
/**
 * Module UtilsService, helps to exports a UtilsService object
 * @module services/utilsService
 * @returns [date string format]
 */
export default new UtilsService();
