// Dependencies
import Backbone from 'backbone';
import Handlebars from 'handlebars';
import EventHub from '~/eventHub';

// Handlebars
import template from '~/templates/common/programaslist.handlebars';
import template2 from '~/templates/common/programas.handlebars';

var ProgramasView = Backbone.View.extend({
    className: 'programasView',
    data: null,

    initialize: function() {
      $('.region-content-row').css('background','url("images/new/fondo.jpg") no-repeat center');
      $('.region-content-row').css('background-size','cover');
      $.get("images/new/fondo-programas.jpg").done(function () {
  			$('.region-content-row').css('background','url("images/new/fondo-programas.jpg") no-repeat center');
  			$('.region-content-row').css('background-size','cover');
  		});
    },

    events: {
        'click .btnhome': 'home',
        'click .btnback': 'listPrograms',
        'click .btnitem': 'renderProgram'
    },

    translate: function(e){
      var that = this;
  		var callback = data => {
        if(arrlang[lang] === undefined){ return }
  			that.$el.find('.item').each(function(index, element){
  				$(this).text(arrlang[lang][$(this).attr("key")]);
  			});
  		}
  		if(Object.keys(window.arrlang).length !== 0){
  			callback();
  		}
  		this.listenTo(EventHub, 'translation', callback);
    },

    listPrograms: function(){
      var handlebarsTemplate = Handlebars.template(template);
      window.spinnerDialog.hide();
      this.$el.html(handlebarsTemplate({program: this.data.infoCollection.models }));
      this.translate();
    },

    renderProgram: function(e){
      var handlebarsTemplate = Handlebars.template(template2);
      var data = null;
      for(var i=0; i<this.data.infoCollection.models.length;++i){
        if(this.data.infoCollection.models[i].id == $(e.currentTarget).attr('id')){
          data = this.data.infoCollection.models[i];
        }
      }
      this.$el.html(handlebarsTemplate(data));
      this.translate();
      window.spinnerDialog.hide();
    },

    render: function() {
      var handlebarsTemplate = Handlebars.template(template);
      this.$el.html(handlebarsTemplate());
      this.translate();
      var that = this;
      this.listenTo(EventHub, 'programas:setAll', data => {
          that.data = data;
          that.listPrograms();
      });
      return this;
    },

	home: function() {
        EventHub.trigger('navigate', 'login', {
            trigger: true
        });
    }
});
export default ProgramasView;
