import ParseService from '~/services/common/parseService';
import Backbone from 'backbone';
import EventHub from '~/eventHub';
import Model from '~/models/parseModel';
import Collection from '~/collections/parseCollection';
import getPushNotificationService from '~/services/common/pushNotificationService';

// model
var UserModel = Model.extend({
    className: "User",
    defaults: {
        'manualTitle': false,
    },
});

// collection
var UserCollection = Collection.extend({
    model: UserModel,
});

var UserService = ParseService.extend({
    sortBy: 'createdAt',
    itemsOnPage: 100,
    model: UserModel,
    collection: UserCollection,
    setPicture: function(picture, success){
        var user = Parse.User.current();
        user.set('picture',picture);
        return user.save(null,{success: success});
    },
    isAuthorized: function() {
        return Parse.User.current();
    },
    authorize: function(login, pwd) {
        return Parse.User.logIn(login, pwd).then(() => {
            EventHub.trigger('login');
        });
    },
    logOut: function() {
        Parse.User.logOut().then(() => {
            EventHub.trigger('logout');
            Backbone.history.loadUrl(Backbone.history.fragment);
        });
        getPushNotificationService().removeAllChannels();
    },
    getUserNiceName: function() {
        // this function returns the nicely formatted user name.
        var firstName = Parse.User.current().attributes.firstName;
        var lastName = Parse.User.current().attributes.lastName;
        var nickname = Parse.User.current().attributes.nickname;
        var email = Parse.User.current().attributes.email;
        var userFormattedName = '';
        var hasFirstName = false;
        var hasLastName = false;
        var hasNickName = false;
        if (firstName) { hasFirstName = true; }
        if (lastName) { hasLastName = true; }
        if (nickname) { hasNickName = true; }
        if (hasFirstName) {
            if (hasLastName) {
                // has both
                userFormattedName = firstName + " " + lastName;
            } else {
                //has only first firstName
                if (hasNickName) {
                    userFormattedName = firstName + " (" + nickname + ")";
                } else {
                    // has no nickname.
                    userFormattedName = firstName;
                }
            }
        }
        else {
            // has NO firstname
            if (hasLastName) {
                // Has lastname, but no firstname
                if (hasNickName) {
                    // has lastname + nickname
                    userFormattedName = lastName + " (" + nickname + ")";
                } else {
                    // has only lastname
                    userFormattedName = lastName;
                }
            } else {
                // has NO lastname either
                if (hasNickName) {
                    // has ONLY nickname
                    userFormattedName = nickname;
                } else {
                    // has NOTHING!
                    userFormattedName = email;
                }
            }
        }
        return userFormattedName;
    },
})

var userService;
export default function() {
    return userService || (userService = new UserService());
};
// only created once for application
