// Dependencies
import Backbone from 'backbone';
import EventHub from '~/eventHub';
import URIJS from 'urijs';
import Rules from '~/models/rulesModel.js';
import Settings from '~/settings.js';
// App view
import AppView from '~/appView';

// Report views
import ReportView from '~/views/report/reportView';

import InsertDetailsCustomReportView from '~/views/report/insertDetailsCustomReportView';
import SelectCategoryView from '~/views/report/selectCategoryView';
import ConfirmReportView from '~/views/report/confirmreportView';
import ProgramaView from '~/views/common/programaView';
import ProgramasView from '~/views/common/programasView';
import SponsorsView from '~/views/common/SponsorsView';
import ConferencistasView from '~/views/report/conferencistasView';
import ConferencistaView from '~/views/report/conferencistaView';
import InformacioncategoriasView from '~/views/report/informacioncategoriasView';
import InformacionsubcategoriasView from '~/views/report/informacionsubcategoriasView';
import InformacionsubconferencistaView from '~/views/report/informacionsubconferencistaView';
import NewsView from '~/views/report/newsView';
import SinglenewsView from '~/views/report/singlenewsView';

// Common views
import LoginView from '~/views/common/loginView';
import SearchView from '~/views/common/searchView';


// Services
import getReportCategoryService from '~/services/report/reportCategoryService';
import getReportService from '~/services/report/reportService';
import getReportSuperCategoryService from '~/services/report/reportSuperCategoryService';
import getInformationService from '~/services/common/informationService';
import getProgramService from '~/services/common/programService';
/**
 * App router (all redirects and links are process from here)
 * @constructor
 */
var AppRouter = Backbone.Router.extend(
  {
    currentLayout: null,
    viewCache: {},
    firstTime: true,
    /** Posible paths of the webSite
     * @namespace AppRouter.routes
     */
    routes: {
        "home(?*querystring)": "homeViewer", // sin, new, in-progress, closed
        "report(/:id)": "reportViewer",
        "login(?uri=*path)": "loginViewer",
        'confirmreport': 'confirmreportView',
        "insertdetailscustomreport": "insertdetailscustomreportViewer",
        "selectcategory": "selectCategoryViewer",
        'programa': 'programaViewer',
        'programas': 'programasViewer',
        'sponsors': 'sponsorsViewer',
    	  'conferencistas': 'conferencistasViewer',
    	  'conferencista(/:id)': 'conferencistaViewer',
        'informacioncategorias': 'informacioncategoriasViewer',
    	  'informacioncategorias(/:id)': 'informacioncategoriaViewer',
        'informacionsubconferencista(/:id)': 'informacionsubconferencistaViewer',
        "myreports": "myReportsViewer",
		    "news": "newsViewer",
		    'singlenews(/:id)': 'singlenewsViewer',
        'search': 'searchViewer',
        '*notFound': 'loginViewer',
        'home2': 'homeViewer2',

    },

    searchViewer: function(){
      this.showLayout('searchcategorie', () => ({
              'content-row': new SearchView(),
          }));
    },

    informacioncategoriasViewer: function(){

      this.showLayout('conferencistas', () => ({
              'content-row': new InformacioncategoriasView(),
          }));
    },

    informacioncategoriaViewer: function(categoriaID) {
          this.showLayout('conferencista', () => ({
              'content-row': [new InformacionsubcategoriasView()],
                      }));
          EventHub.trigger('conferencista:setState', { categoriaID: categoriaID });
      },
      informacionsubconferencistaViewer: function(categoriaID) {
            this.showLayout('conferencistainformation', () => ({
                'content-row': [new InformacionsubconferencistaView()],
                        }));
            EventHub.trigger('conferencista:setState', { conferencistaID: categoriaID });
        },

    initialize: function() {
        this.appView = new AppView({
            el: $('.app')
        });
        this.appView.render();
    },

    execute: function(callback, args, name) {
        window.scrollTo(0,0);
        if (callback) callback.apply(this, args);
    },

    // prevent layout rerendering
    showLayout: function(layoutName, getLayout, layoutType) {
        window.spinnerDialog.show();
        if (this.currentLayout != layoutName) {
            this.currentLayout = layoutName;
            this.appView.renderLayout(getLayout(), layoutType);
        }
    },

    helpViewer: function() {
        this.showLayout('help', () => ({
            'content-row': new HelpView() // this view is not cached, so it will be rerendered when we left this layout
        }), 'fullwidth-light');
    },

    myReportsViewer: function(){
      this.showLayout('myReports', () => ({
          'content-row': new MyReportsView() // this view is not cached, so it will be rerendered when we left this layout
      }), 'fullwidth-light');
      var reportsPromise = getReportService().fetch(
        { all: true, cache: false, limit: 10, descending: true, rules: {
            equals: [{ key: 'user', val: { __type: "Pointer", className: "_User", objectId: Parse.User.current().id } }],
            select: ['id', 'geometry', 'category', 'description', 'subcategory' ],
            includes: ['category','category.name','subCategory','subCategory.name','category.superCat','category.superCat.sqicon','subCategory.superCat','subCategory.superCat.sqicon']
          }
        });
      Promise.all([reportsPromise]).then(([reportsCollection]) => {
          EventHub.trigger('myReports:setAll', {
              reportsCollection: reportsCollection
          });
      });
    },

    insertdetailscustomreportViewer: function(queryString= null){
      var uri = new URIJS('?' + queryString);
      var query = uri.search(true);
      this.showLayout('insertdetailscustomreport', () => ({
        'content-row': new InsertDetailsCustomReportView() // this view is not cached, so it will be rerendered when we left this layout
      }), 'fullwidth-light');
      EventHub.trigger('customreport:details',{supercategory: query['supercateg'], subcategory: query['subcateg'], customform: query['form'] });
    },

    confirmreportView: function(){
        this.showLayout('confirmreport', () => ({
          'content-row': new ConfirmReportView() // this view is not cached, so it will be rerendered when we left this layout
      }), 'fullwidth-light');
    },

    selectCategoryViewer: function(queryString = null) {
      if (localStorage.searchx == undefined) {
        EventHub.trigger('navigate', 'search', { trigger: true });
      }else{
        this.showLayout('select-category', () => ({
            'content-row': [
                new SelectCategoryView()
            ],
        }));
        var uri = new URIJS('?' + queryString);
        var query = uri.search(true);
        EventHub.trigger('report:details2',{subcategory: query['subcat'], supercategory: query['supercat']});
        EventHub.trigger('reports:resetState');
      }
    },

    startReportViewer: function(queryString = null) {
        this.showLayout('start-report', () => ({
            header: new StartReportHeaderView(),
            'content-row': [
                this.viewCache.MapView = new MapView(),
            ],
            footer: new StartReportFooterView()
        }));
        var uri = new URIJS('?' + queryString);
        var query = uri.search(true);
        EventHub.trigger('reports:resetState');
    },

    homeViewer: function() {
      EventHub.trigger('navigate', 'selectcategory', {
          trigger: true
      });
    },

    homeViewer2: function(){
        this.showLayout('home', () => ({
            header: new SummaryReportsView(),
            'content-row': [
                 new MapView(), // this view is cached, so it will be created and rendered one lime only
            ],
            footer: new FooterMenuView()
        }));;
        var categoryPromise = getReportCategoryService().fetch({ all: true, cache: true });

        Promise.all([categoryPromise]).then(([categoriesCollection]) => {
            
        });
        EventHub.trigger('reports:resetState');
    },

    loginViewer: function(backToUri) {
        this.showLayout('login', () => ({
            'content-row': new LoginView(),
        }));
        var categoryPromise = getReportSuperCategoryService().fetch({
          all: true,
          cache: true
        });
        EventHub.trigger('login:changelanguaje');
    },

    reportViewer: function(reportID) {
        EventHub.trigger('sidebarView:setActive', '#report'); // select item in sidebar
        this.showLayout('report', () => ({
            'content-row': [new ReportView()],
                    }));
        EventHub.trigger('report:setState', { reportID: reportID });
    },


  	programaViewer: function(){
  		  this.showLayout('programa', () => ({
              'content-row': new ProgramaView(),
        }));
        var infoPromise = getInformationService().fetch(
          { all: true, cache: true, limit: 10, descending: true, rules: {
            //posteriormente generalizar y que venga de id.json
              equals: [{ key: 'event', val: 'CLAPFO' }]
            }
          });
        Promise.all([infoPromise]).then(([infoCollection]) => {
            EventHub.trigger('programa:setAll', {
                infoCollection: infoCollection
            });
        });
  	},

    programasViewer: function(){
        this.showLayout('programas', () => ({
              'content-row': new ProgramasView(),
        }));
        var infoPromise = getProgramService().fetch({ all: true, cache: true, limit: 1000, descending: true });
        Promise.all([infoPromise]).then(([infoCollection]) => {
            EventHub.trigger('programas:setAll', {
                infoCollection: infoCollection
            });
        });
    },

    sponsorsViewer: function(){
  		  this.showLayout('sponsors', () => ({
              'content-row': new SponsorsView(),
        }));
        var infoPromise = getInformationService().fetch(
          { all: true, cache: true, limit: 10, descending: true, rules: {
            //posteriormente generalizar y que venga de id.json
              equals: [{ key: 'event', val: 'CLAPFO' }]
            }
          });
        Promise.all([infoPromise]).then(([infoCollection]) => {
            EventHub.trigger('sponsors:setAll', {
                infoCollection: infoCollection
            });
        });
  	},

	conferencistasViewer: function(){
		this.showLayout('conferencistas', () => ({
            'content-row': new ConferencistasView(),
        }));
	},

	newsViewer: function(){
		this.showLayout('news', () => ({
            'content-row': new NewsView(),
        }));
	},

	singlenewsViewer: function(singlenewsID) {
        this.showLayout('singlenews', () => ({
            'content-row': [new SinglenewsView()],
                    }));
        EventHub.trigger('singlenews:setState', { singlenewsID: singlenewsID });
    },

	conferencistaViewer: function(conferencistaID) {
        this.showLayout('conferencista', () => ({
            'content-row': [new ConferencistaView()],
                    }));
        EventHub.trigger('conferencista:setState', { conferencistaID: conferencistaID });
    },
});
export default AppRouter;
