import Settings from '~/settings.js';
import Parse from 'parse';
import Backbone from 'backbone';
import HashService from '~/services/common/hashService';

// this is base service for all Parse operations
function ParseService() {
    this.initialize();
}
ParseService.extend = Backbone.Model.extend; // extend helper from Backbone

var isInitialized = false; // shared between all Parse services (used to initialize Parse settings one time only)
ParseService.prototype = {
    cache: {}, // used for caching collection
    itemsOnPage: 1000, // max number of items in response
    cacheMinutes: 10, // default cache for collections, can be overriden in service class
    initialize: function() {
        if (!isInitialized) {
            isInitialized = true;
            Parse.serverURL = Settings.parseUrl;
            Parse.initialize(Settings.parseAppId, Settings.parseKey);
        }
    },

    // create Parse query
    _getQuery: function(pageNumber = 0, settings) {
        var query;

        query = new Parse.Query(this.model);
        var itemsCount = this.itemsOnPage;
        if(settings.limit)
        {
          itemsCount = settings.limit;
        }
        if(settings.page)
        {
            pageNumber = settings.page;
        }
        query = query.limit(itemsCount);
        if(this.order && this.order == 'dsc'){
          query.descending(this.sortBy);
        }
        else {
          query.ascending(this.sortBy);
        }
        query.skip(itemsCount * pageNumber);
        query = this._processRules(query, settings.rules);
        query = this._processIncludes(query, settings.includes);
        query = this._processProximity(query, settings.near);
        return query;
    },

    _getFullQuery: function(settings) {
        var query;
        var pageNumber = 0;
        query = new Parse.Query(this.model);
        var itemsCount = this.itemsOnPage;
        if(settings.limit)
        {
          itemsCount = settings.limit;
        }
        if(settings.page)
        {
            pageNumber = settings.page;
        }
        query = query.limit(itemsCount);
        query.skip(itemsCount * pageNumber);
        query.ascending(this.sortBy);
        query = this._processRules(query, settings.rules);
        query = this._processIncludes(query, settings.includes);
        query = this._processProximity(query, settings.near);
        return query;
    },

    _processRules: function(query, rules) {
        if (rules) {
            if(rules.includes)
            {
              for (var i=0; i< rules.includes.length;++i) {
                  query.include(rules.includes[i]);
              }
            }
            for (var i = 0; rules.greaterThanOrEqualTo && i < rules.greaterThanOrEqualTo.length; ++i) {
                query.greaterThanOrEqualTo(rules.greaterThanOrEqualTo[i].key, rules.greaterThanOrEqualTo[i].val);
            }

            for (var i = 0; rules.notequals && i < rules.notequals.length; ++i) {
                query.notEqualTo(rules.notequals[i].key, rules.notequals[i].val);
            }

            for (var i = 0; rules.equals && i < rules.equals.length; ++i) {
                query.equalTo(rules.equals[i].key, rules.equals[i].val);
            }
            for (var key in rules.lessThanOrEqualTo) {
                if (rules.lessThanOrEqualTo.hasOwnProperty(key)) {
                    query.lessThanOrEqualTo(key, rules.lessThanOrEqualTo[key]);
                }
            }
            for (var key in rules.contains) {
                if (rules.contains.hasOwnProperty(key)) {
                    query.contains(key, rules.contains[key]);
                }
            }
            for (var key in rules.matchesQuery) {
                if (rules.matchesQuery.hasOwnProperty(key)) {
                    query.matchesQuery(key, rules.matchesQuery[key].toParseQuery());
                }
            }
            if(rules.select){
              query.select(rules.select);
            }
            if (rules.or) {
                query.equalTo(rules.or.key, rules.or.val);
            }
        }
        return query;
    },

    // fetch Parse data for one page only
    _fetchPage: function(collection, pageNumber = 0, settings) {
        var query = this._getQuery(pageNumber, settings);
        if (query) {
            return query.find().then(models => {
                try {
                    collection.add(models);
                } catch (e) {
                    console.error(e);
                }
                return [collection, models];
            });
        } else {
            return Promise.reject("No query");
        }

    },

    // recursive fetch to load all pages of Parse data
    _fetchRecursive: function(collection, page, settings) {
        return new Promise((resolve, reject) => {
            this._fetchPage(collection, page, settings).then(([collection, models]) => {
                if (settings && settings.all && models.length && models.length == this.itemsOnPage) {
                    // load next page of data
                    this._fetchRecursive(collection, ++page, settings).then(args => {
                        // resolve promise if 'waitAll' is set to 'true' (promise will be resolved when all data will be loaded)
                        if (settings && settings.all && settings.waitAll) {
                            resolve(args);
                        }
                    }, err => {
                        reject(err);
                    });
                } else {
                    // resolve if this is last page of data (e.g. number of models is less than 'itemsOnPage')
                    collection.trigger('isLoading', false); // is loading data
                    resolve([collection, models]);
                    return;
                }

                // resolve promise if 'waitAll' is not set to 'true' (promise will be resolved when first page will be loaded)
                if (!settings || !settings.all || !settings.waitAll) {
                    resolve([collection, models]);
                }
            });
        }).catch((err) => console.log(err));
    },
    _processIncludes: function(query, includes) {
        if (includes) {
            for (var i = 0; i < includes.length; ++i) {
                query.include(includes[i]);
            }
        }
        return query;
    },

    _processProximity: function(query, near) {
        if (near) {
            var parsePoint = new Parse.GeoPoint({ latitude: near.latitude, longitude: near.longitude });
            query.withinKilometers("geometry", parsePoint, near.maxDistance);
        }
        return query;
    },
    /**
     * generate cache key
     * @todo improve key generation so it's generated from the query
     */
    generateCacheKey: function(settings) {
        return HashService.value(this._getFullQuery(settings));
    },

    // search result in cache
    getCachedValue: function(settings) {
        var key = this.generateCacheKey(settings);
        var cachedRecord = this.cache[key];
        if (cachedRecord) {
            if (cachedRecord.expData >= new Date()) {
                return cachedRecord.collection; // item is not expired, so we return it
            } else {
                delete this.cache[key]; // item is expired, so we remove it from cache
            }
        }
    },

    // save result to cache
    addCachedValue: function(collection, settings) {
        var expDate = new Date();
        expDate.setMinutes(expDate.getMinutes() + this.cacheMinutes); // cache data for 10 minutes (can be overriden globally in this file or specifically for some service)

        this.cache[this.generateCacheKey(settings)] = {
            collection: collection,
            expData: expDate
        }
    },

    // fetch items from Parse
    // settings:
    // {
    //   page: numeric (default is 0)
    //   all: boolean (default is false) - load all items. promise will be resolved right after returning items for the first page
    //   waitAll: boolean(default is false) - resolves promise only when all pages will be received
    //   cache: boolean(default is false) - caches collection
    // }
    fetch: function(settings) {

        // check cache for cached value
        if (settings && settings.cache) {
            var cachedValue = this.getCachedValue(settings);
            if (cachedValue) {
                return new Promise((resolve, reject) => {
                    resolve(cachedValue);
                }).catch((err) => console.log(err));
            }
        }

        return new Promise((resolve, reject) => {
            var collection = new this.collection(); // create collection
            collection.trigger('isLoading', true); // is loading data
            var page = (settings && settings.page) || 0; // set page
            this._fetchRecursive(collection, page, settings).then(([collection, models]) => {
                if (settings && settings.cache) {
                    this.addCachedValue(collection, settings); // cache value
                }
                resolve(collection); // resolve promise
            }, (err) => reject(err));
        });
    },

    fetchById: function(id, settings) {
        var query = new Parse.Query(this.model);
        return query.get(id);
    },

    save: function(model) {
        model.save(null, {
            success: function() {

            },
            error: function(e) {
                console.log('error: ' + e);
            }
        });

    },
    destroy: function(models) {
        return Parse.Object.destroyAll(models);
    }

}

export default ParseService;
