import Backbone from 'backbone';
import EventHub from '~/eventHub';
import ParseService from '~/services/common/parseService';
import Model from '~/models/parseModel';
import Collection from '~/collections/parseCollection';
import {deepEqual} from 'fastequals';

var localizationService;
export default function() {

    var LocalizationModel = Model.extend({
        className: "Localization",
        defaults: {}
    });

    var LocalizationCollection = Collection.extend({
        model: LocalizationModel
    });

    var LocalizationService = ParseService.extend({
        sortBy: 'createdAt',
        model: LocalizationModel,
        collection: LocalizationCollection,
        getLanguages: function(){
          var languagearray = {};
          var localizationPromise = this.fetch({ all: true, cache: true,});
          Promise.all([localizationPromise]).then(([data]) => {
            var langs = data.models;
            for(var i=0; i < langs.length; i++){
              languagearray[langs[i].attributes.abbreviation] = JSON.parse(langs[i].attributes.staticlang);
              languagearray[langs[i].attributes.abbreviation]["Supercategories"] = JSON.parse(langs[i].attributes.catlang);
              languagearray[langs[i].attributes.abbreviation]["LanguageName"] = langs[i].attributes.name;
            }

            var appLang = navigator.language.split("-")[0];
            if(languagearray[appLang]){
              if(window.storedLang === null || window.storedLang === undefined){
                window.lang = appLang;
              } else {
                if(languagearray[window.storedLang] === undefined){
                  window.lang = appLang;
                }
              }
            }

            if(languagearray[window.lang] === undefined){
              window.lang = window.defaultLang;
            }
            localStorage.setItem("lang", window.lang);

            var setArrLang = () => {
              localStorage.setItem("arrlang", JSON.stringify(languagearray));
              window.arrlang = languagearray;
              EventHub.trigger('translation', {});
            }

            if(Object.keys(window.arrlang).length !== 0){
              if(!deepEqual(window.arrlang, languagearray)){
                setArrLang();
              }
            } else {
              setArrLang();
            }
          });
        }
    });

    return localizationService || (localizationService = new LocalizationService());
};
